import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDataContext } from "../../data/providers/DataProvider";
import { formatNumber } from "../../services/AssetHelpers";

const LandsSlider = () => {

    const { lands } = useDataContext();
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div id="homeLandSliderSection">
            {/* <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7"> */}
            <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90">
                <div className="container-fluid container-lg">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Bien immobilier</h6>
                                <h1 className="section-title">Nos Terrains</h1>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1"> */}
                    <div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
                    {
                        lands.length > 0
                        ?
                        lands.slice(0, 6).map((land, index) => {
                            return (
                                // {/* ltn__product-item */}
                                <div key={index} className="col-xl-4 col-sm-6 col-12">
                                {/* <div className="col-12" key={index}> */}
                                    <div className="ltn__product-item ltn__product-item-4 text-center---">
                                        <div className="product-img go-top">
                                            <Link to={`/terrain/${land.id}`}>
                                                <img
                                                    // src={publicUrl + "assets/img/product-3/1.jpg"}
                                                    src={`${publicUrl}assets/img/lands/image-terrain-${land.real_estate.municipality_id === 432 ? 'pout' : 'sangalkam'}.jpg`}
                                                    // src={land.real_estate.images[0].data}
                                                    alt="Illustration"
                                                />
                                            </Link>
                                            <div className="product-badge">
                                                <ul>
                                                    <li className="sale-badge">À vendre</li>
                                                </ul>
                                            </div>
                                            {/* <div className="real-estate-agent">
                                                <div className="agent-img">
                                                    <a href="#/team-details">
                                                        <img src="/tf/react/quarter-preview/quarter/assets/img/blog/author.jpg" alt="#" />
                                                    </a>
                                                </div>
                                            </div> */}
                                            <div className="product-img-location-gallery">
                                                <div className="product-img-location">
                                                    <ul>
                                                        <li>
                                                            <Link to="/contact">
                                                                <i className="flaticon-pin" />{" "}
                                                                {land.real_estate.municipality.name}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="real-estate-agent">
                                                    <div className="agent-img bg-white rounded-circle p-1">
                                                        <a href="#/team-details">
                                                            <img
                                                                src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                alt="Mascotte ALFAIMMO"
                                                                width={32}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <div className="product-img-gallery go-top">
                                                    <ul>
                                                        <li>
                                                            <Link to={`/terrain/${land.id}`}>
                                                                <i className="fas fa-camera" />{" "}
                                                                4
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/terrain/${land.id}`}>
                                                                <i className="fas fa-film" />{" "}
                                                                2
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <div className="product-price">
                                                <small>
                                                <span>{formatNumber(land.real_estate.price)} <label>FCFA</label></span>
                                                {" - "}
                                                {
                                                    land.real_estate.municipality_id === 432 &&
                                                    <span>{formatNumber(land.real_estate.monthly_price)} <label>FCFA</label><label>/Month</label></span>
                                                }
                                                </small>
                                            </div>
                                            <h2 className="product-title go-top">
                                                <Link to={`/terrain/${land.id}`}>Terrain nu</Link>
                                            </h2>
                                            <div className="product-description">
                                                <p>{land.real_estate.description}</p>
                                            </div>
                                            <ul className="ltn__list-item-2 ltn__list-item-2-before">
                                                <li className="d-inline-flex align-items-center gap-2">
                                                    <span>Supercifie:</span>
                                                    <span>{land.real_estate.superficies} m<sup>2</sup></span>
                                                </li>
                                                <li className="d-inline-flex align-items-center gap-2">
                                                    <span>Viabilisé:</span>
                                                    <span>{land.serviced === 'YES' ? 'Oui' : 'Non'}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                // {/* ltn__product-item */}
                            );
                        })
                        :
                        <div className="d-flex align-items-center justify-content-center py-5">
                            <div className="w-100 d-flex align-items-center justify-content-center alert alert-primary">
                                <p className="m-0">Aucun terrain disponible pour le moment</p>
                            </div>
                        </div>
                    }
                    </div>
                </div>
                <div className="btn-wrapper text-center go-top">
                    <Link to="/terrains" className="btn theme-btn-1 btn-effect-1 text-uppercase">Voir tout</Link>
                </div>
            </div>
        </div>
    );
};

export default LandsSlider;
