import React from 'react';
import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import Content from '../components/real-estate-components/apartment-components/Content';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';

const Apartments = () => {
    return <div>
        <Navbar2 />
        <PageHeader headertitle="Appartements" />
        <Content />
        <Newsletter />
        <Footer />
    </div>
};

export default Apartments;
