import React, { useContext, useEffect, useRef, useState } from "react";

import LoaderSpinnerContext from "../contexts/LoaderSpinnerContext";

/**
 * 
 * @returns 
 */
export const useLoaderSpinnerContext = () => useContext(LoaderSpinnerContext);

/**
 * 
 * @param param0 
 * @returns 
 */
const LoaderSpinnerProvider = ({ children }) => {
    /**
     * 
     */
    const [show, setShow] = useState(false);

    /**
     * 
     */
    const wrapper = useRef(null);

    /**
     * 
     */
    useEffect(() => {
        if(wrapper.current === null){
            wrapper.current = document.querySelector('#loaderSpinnerWrapper');
        }   
        if(show){
            wrapper?.current?.classList.add('d-block');
        }
        else{
            wrapper?.current?.classList.remove('d-block');
        }
    }, [show]);

    /**
     * 
     * @param show 
     */
    // const setShowLoader = (show : boolean) => {
    //     _setShowLoader(show);
    // };

    /**
     * The store object
     */
    const state = {
        show,
        setShow,
    };

    /**
     * Wrap the application in the provider with the initialized context
     */
    return <LoaderSpinnerContext.Provider value={state}>{children}</LoaderSpinnerContext.Provider>;
};

export default LoaderSpinnerProvider;
