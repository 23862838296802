import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import ApartmentSlider from '../components/real-estate-components/apartment-components/ApartmentSlider';
import ApartmentDetails from '../components/real-estate-components/apartment-components/ApartmentDetails';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';
import { useDataContext } from '../data/providers/DataProvider';
import { useLoaderSpinnerContext } from '../components/global-components/providers/LoaderSpinnerProvider';

const Apartment = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { apartments } = useDataContext();
    const { id: apartmentId } = useParams();
    const [apartment, setApartment] = useState(undefined);

    useEffect(() => {

        if(apartment){
            setShowLoaderSpinner(false);
        }

    }, [apartment, setShowLoaderSpinner]);

    useEffect(() => {

        const _apartment = apartments.find((l) => l.id === Number(apartmentId));
        setApartment(_apartment);

    }, [apartmentId, apartments]);

    return (
        <div>
            <Navbar2 />
            <PageHeader headertitle="Appartement" customclass="mb-0" />
            {/* <ApartmentSlider apartment={apartment} /> */}
            {/* {apartment && <ApartmentSlider apartment={apartment} />}*/}
            <div className='py-5'></div>
            {apartment && <ApartmentDetails apartment={apartment} />}
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Apartment;
