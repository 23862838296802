import { useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { useLoaderSpinnerContext } from "../providers/LoaderSpinnerProvider";

/**
 * 
 * @returns 
 */
const useGoTo = () => {

    const navigate = useNavigate();

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();

    /**
     * 
     * @param to 
     * @param options 
     * @param showLoader 
     */
    const goTo = useCallback((to, options = undefined, showLoader = false) => {

        if(showLoader){
            setShowLoaderSpinner(true);
        }
		navigate(to, options);
	}, [navigate, setShowLoaderSpinner]);
    return goTo;
};

export default useGoTo;
