import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import Swal from 'sweetalert2';
import { useResearchDataContext } from "../providers/ResearchDataProvider";
import Select from 'react-select';

import {
    customSelectClearIndicator,
    customSelectControl,
    customSelectDropdownIndicator,
    customSelectMenu,
    customSelectMenuList,
    customSelectOption,
    customSelectPlaceholder,
    customSelectStyles,
    customSelectValueContainer
} from "../../global-components/select-customizer";
import { useDataContext } from "../../../data/providers/DataProvider";
import RangeSlider from "../../global-components/ui/RangeSlider";

const LandSearch = () => {

    const { regions, municipalities} = useDataContext();
    const researchData = useResearchDataContext();

    const priceRangeInputRef = useRef(null);
    const regionsSelectRef = useRef(null);
    const municipalitiesSelectRef = useRef(null);
    const [municipalitiesByRegion, setMunicipalitiesByRegion] = useState([]);
    const [disableMunicipalitiesSelect, setDisableMunicipalitiesSelect] = useState(true);

    const navigate = useNavigate();

    /**
     * 
     * @param region 
     */
    const changeRegion = (region) => {
        researchData.setLandRegion(region);

        if(region === null || region === undefined){
            setMunicipalitiesByRegion([]);
            municipalitiesSelectRef.current.clearValue();
            setDisableMunicipalitiesSelect(true);
            // setIsValidComplainantRegion(false);
        }
        else{
            setMunicipalitiesByRegion(municipalities.filter((municipality) => municipality.region_id === region.id));
            setDisableMunicipalitiesSelect(false);

            if(researchData.landMunicipality !== null &&
                    researchData.landMunicipality !== null &&
                researchData.landMunicipality?.region_id !== region.id){
                    municipalitiesSelectRef.current.clearValue();
            }
            // setIsValidComplainantRegion(true);
        }
    };

    const searchLand = () => {

        let errorMesg = '';

        if(!researchData.landRegion){
            errorMesg = 'Veuillez choisir une région';
        }
        else if(!researchData.landMunicipality){
            errorMesg = 'Veuillez choisir une commune';
        }
        else if(!researchData.landSuperficiesRange){
            errorMesg = 'Veuillez choisir une superficie';
        }
        // else if(!researchData.serviced){
        //     errorMesg = 'Veuillez choisir la viabilité';
        // }
        if(errorMesg.length > 0){
            Swal.fire({
                text: errorMesg,
                icon: 'warning',
                iconColor: '#b02127',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonColor: '#b02127', 
                confirmButtonText: "D'accord !"
            });
        }
        else{
            navigate("/terrains");
        }
    };

    return (
        <div className="car-dealer-form-inner">
            <form
                action="#"
                className="ltn__car-dealer-form-box row"
            >
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-12 mb-0">
                    <div className="d-flex align-items-center gap-4 h-100" style={{paddingBottom: '30px'}}>
                        <div className="">
                            <label className="fw-bold">Viabilisé:</label>
                        </div>
                        <div className="d-flex align-items-center gap-2 gap-sm-4">
                            {/* <label className="checkbox-item">
                                Oui
                                <input
                                    type="radio"
                                    name="serviced"
                                    checked={researchData.serviced === 'YES'}
                                    onChange={(e) => researchData.setServiced('YES')}
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="checkbox-item">
                                Non
                                <input
                                    type="radio"
                                    name="serviced"
                                    checked={researchData.serviced === 'NO'}
                                    onChange={(e) => researchData.setServiced('NO')}
                                />
                                <span className="checkmark" />
                            </label>
                            <label className="checkbox-item">
                                Tout
                                <input
                                    type="radio"
                                    name="serviced"
                                    checked={researchData.serviced === null}
                                    onChange={(e) => researchData.setServiced(null)}
                                />
                                <span className="checkmark" />
                            </label> */}
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="serviced"
                                    id="notServiced"
                                    value="YES"
                                    checked={researchData.serviced === 'YES'}
                                    onChange={(e) => researchData.setServiced('YES')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="serviced"
                                >
                                    Oui
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="serviced"
                                    id="serviced"
                                    value="NO"
                                    checked={researchData.serviced === 'NO'}
                                    onChange={(e) => researchData.setServiced('NO')}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="notServiced"
                                >
                                    Non
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="serviced"
                                    id="servicedAndNotServiced"
                                    value=""
                                    checked={researchData.serviced === null}
                                    onChange={(e) => researchData.setServiced(null)}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="servicedAndNotServiced"
                                >
                                    Tout
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-ring col-md-6 col-lg-4 mb-0">
                    <div className="input-item input-item-name ltn__custom-icon">
                        <input
                            type="text"
                            name="superficies"
                            placeholder="Superficie"
                            className="personalized-input"
                            onInput={(e) => researchData.setLandSuperficiesRange(e.target.value)}
                        />
                    </div>
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-md-6 col-lg-4">
                    <Select
                        ref={regionsSelectRef}
                        components={{
                            Control: customSelectControl,
                            ClearIndicator: customSelectClearIndicator,
                            DropdownIndicator: customSelectDropdownIndicator,
                            Menu: customSelectMenu,
                            MenuList: customSelectMenuList,
                            Option: customSelectOption,
                            Placeholder: customSelectPlaceholder,
                            ValueContainer: customSelectValueContainer
                        }}
                        isClearable={true}
                        isSearchable={true}
                        menuPlacement='auto'
                        // menuPortalTarget={document.body}
                        name='region'
                        options={regions}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        placeholder='Région'
                        styles={customSelectStyles}
                        onChange={changeRegion}
                    />
                </div>
                <div className="ltn__car-dealer-form-item ltn__custom-icon ltn__icon-car col-md-6 col-lg-4">
                    <Select
                        ref={municipalitiesSelectRef}
                        components={{
                            Control: customSelectControl,
                            ClearIndicator: customSelectClearIndicator,
                            DropdownIndicator: customSelectDropdownIndicator,
                            Menu: customSelectMenu,
                            MenuList: customSelectMenuList,
                            Option: customSelectOption,
                            Placeholder: customSelectPlaceholder,
                            ValueContainer: customSelectValueContainer
                        }}
                        isClearable={true}
                        isSearchable={true}
                        isDisabled={disableMunicipalitiesSelect}
                        menuPlacement='auto'
                        // menuPortalTarget={document.body}
                        name='municipality'
                        options={municipalitiesByRegion}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.name}
                        placeholder='Commune'
                        styles={customSelectStyles}
                        onChange={researchData.setLandMunicipality}
                    />
                </div>
                <div className="car-price-filter-range col-lg-12">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9 col-xl-8">
                            <div className="price_filter">
                                <label className="mb-3">Fourchette de prix:{" "}</label>
                                {/* <div className="price_slider_amount d-flex flex-column flex-ms-row gap-2">
                                    <label className="mb-4">Fourchette de prix:{" "}</label>
                                    <input
                                        type="text"
                                        className="amount mb-4"
                                        name="price-range-to-display"
                                        placeholder="Fourchette de prix"
                                    />
                                    <input
                                        type="hidden"
                                        className="price-range"
                                        name="price-range"
                                        placeholder=""
                                        ref={priceRangeInputRef}
                                    />
                                </div>
                                <div className="slider-range" /> */}
                                <div className="position-relative">
                                    <RangeSlider
                                        min={125000}
                                        max={15200055}
                                        onChange={({ min, max }) => {
                                            // console.log(`min = ${min}, max = ${max}`)
                                            researchData.setLandPriceRange(`${min}-${max}`);
                                        }}
                                        // leftSliderCurrentValue={priceRangeLimits ? parseInt(priceRangeLimits[0]) : 125000}
                                        // rightSliderCurrentValue={priceRangeLimits ? parseInt(priceRangeLimits[1]) : 4500000}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="btn-wrapper text-center go-top">
                        <button
                            type="button"
                            className="btn theme-btn-1 btn-effect-1 text-uppercase"
                            onClick={searchLand}
                        >
                            Rechercher
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LandSearch;
