import React, { useCallback, useEffect, useState, useRef } from "react";

import classnames from "classnames";
import PropTypes from "prop-types";
import { formatNumber } from "../../../services/AssetHelpers";

import "./RangeSlider.css";

const RangeSlider = ({ min, max, onChange, leftSliderCurrentValue = null, rightSliderCurrentValue = null }) => {

    const [minVal, setMinVal] = useState(leftSliderCurrentValue ? leftSliderCurrentValue : min);
    const [maxVal, setMaxVal] = useState(rightSliderCurrentValue ? rightSliderCurrentValue : max);
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const range = useRef(null);

    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(minVal);
            const maxPercent = getPercent(+maxValRef.current.value); // Preceding with '+' converts the value from type string to type number

            if (range.current) {
                range.current.style.left = `${minPercent}%`;
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(maxVal);

            if (range.current) {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        onChange({ min: minVal, max: maxVal });
    }, [minVal, maxVal, onChange]);

    return (
        <div className="m-range-slider-wrapper">
            <div className="fw-bold d-flex align-items-center justify-content-between mb-3 gap-1">
                {/* <div>{formatNumber(minVal, minVal !== 1000000 ? 'standard' : 'compact')} <small>FCFA</small></div> */}
                {/* <div>{formatNumber(maxVal, maxVal !== 1000000 ? 'standard' : 'compact')} <small>FCFA</small></div> */}
                <div>{formatNumber(minVal)} <small>FCFA</small></div>
                <div>{formatNumber(maxVal)} <small>FCFA</small></div>
            </div>
            <input
                type="range"
                min={min}
                max={max}
                value={minVal}
                ref={minValRef}
                onChange={(event) => {
                    // const value = Math.min(+event.target.value, maxVal - 1);
                    const value = Math.min(+event.target.value, maxVal);
                    setMinVal(value);
                    event.target.value = value.toString();
                }}
                className={classnames("thumb thumb--zindex-3", {
                    "thumb--zindex-5": minVal > max - 100,
                })}
                // className={`thumb thumb--zindex-3 ${minVal > max - 100 ? 'thumb--zindex-5' :''}`}
            />
            <input
                type="range"
                min={min}
                max={max}
                value={maxVal}
                ref={maxValRef}
                onChange={(event) => {
                    // const value = Math.max(+event.target.value, minVal + 1);
                    const value = Math.max(+event.target.value, minVal);
                    setMaxVal(value);
                    event.target.value = value.toString();
                }}
                className="thumb thumb--zindex-4"
            />

            <div className="slider">
                <div className="slider__track" />
                <div ref={range} className="slider__range" />
                {/* <div className="slider__left-value">{formatNumber(minVal, minVal < 1000000 ? 'standard' : 'compact')}</div> */}
                {/* <div className="slider__right-value">{formatNumber(maxVal, maxVal < 1000000 ? 'standard' : 'compact')}</div> */}
            </div>
        </div>
    );
};

RangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default RangeSlider;
