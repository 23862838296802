import React, { useState } from 'react';
import Navbar from '../components/global-components/Navbar';
import Banner from '../components/section-components/Banner';
import ProductListing from '../components/section-components/product-listing';
import Featuresv1 from '../components/section-components/features-v1';
import ProSlider from '../components/section-components/product-slider-v1';
import Apartment from '../components/section-components/apartment-v1';
import VideoV1 from '../components/section-components/video-v1';
import Category from '../components/section-components/category-v1';
import Testimonial from '../components/section-components/testimonial-v1';
import BlogSlider from '../components/blog-components/blog-slider-v1';
import CallToActionV1 from '../components/section-components/call-to-action-v1';
import Footer from '../components/global-components/Footer';
import LandsSlider from '../components/section-components/LandsSlider';
import HousesSlider from '../components/section-components/HousesSlider';
import ApartmentsSlider from '../components/section-components/ApartmentsSlider';
import HomeVideo from '../components/section-components/HomeVideo';
import Amenities from '../components/section-components/amenities';
import Newsletter from '../components/global-components/Newsletter';
import Testimonials from '../components/section-components/Testimonials';

const Home = () => {
    // const [isLoaded, setLoaded] = useState(false)

    // React.useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src = process.env.PUBLIC_URL + "/assets/js/plugins.js";
    //     script.addEventListener("load", () => setLoaded(true));
    //     document.body.appendChild(script);
    // }, []);

    // React.useEffect(() => {
    //     if (isLoaded) {
    //         // ...
    //     }
    // }, [isLoaded])

    // if(!isLoaded){
    //     return <div></div>
    // }
    return (
        <div>
            <Navbar CustomClass="ltn__header-transparent gradient-color-2" /> 
            <Banner />
            {/* <ProductListing /> */}
            <LandsSlider />
            <HousesSlider />
            <ApartmentsSlider />
            {/* <Featuresv1 customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/> */}
            {/* <ProSlider /> */}
            {/* <Apartment /> */}
            {/* <Amenities /> */}
            <HomeVideo />
            {/* <Category /> */}
            <Testimonials />
            {/* <BlogSlider customClass="section-subtitle-2"/> */}
            {/* <CallToActionV1 /> */}
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Home;
