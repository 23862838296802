import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/SocialsNetwork";
import Copyright from "./Copyright";

const Footer_v1 = () => {

    useEffect(() => {
        const $ = window.$;

        let minscript = document.createElement("script");
        minscript.async = true;
        minscript.id = "mainJS";
        minscript.src = `${process.env.PUBLIC_URL}/assets/js/main.js`;

        if(document.getElementById("mainJs")){
            console.log('ICI')
            document.getElementById("mainJs").remove()
        }
        document.body.appendChild(minscript);
        // if(!n){
        // }
        // else{
        //     n.replaceWith(minscript);
        // }
        $(".go-top")
            .find("a")
            .on("click", function () {
                $(".quarter-overlay").fadeIn(1);

                $(window).scrollTop(0);

                setTimeout(function () {
                    $(".quarter-overlay").fadeOut(300);
                }, 800);
            });

        $(document).on("click", ".theme-btn-1 ", function () {
            $("div").removeClass("modal-backdrop");
            $("div").removeClass("show");
            $("div").removeClass("fade");
            $("body").attr("style", "");
        });
    }, []);

    let imgattr = "Footer logo";

    return (
        <footer className="ltn__footer-area">
            <div className="footer-top-area section-bg-2 plr--5">
                <div className="container-fluid container-md container-xl">
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="footer-widget footer-about-widget">
                            <div className="footer-logo">
                                <div className="site-logo">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/img/logos/logo.png`}
                                        alt="Logo"
                                        width="115"
                                    />
                                </div>
                            </div>
                            <p>Vente, location et gérance de biens immobiliers</p>
                            
                            <div className="ltn__social-media mt-20">
                                <Social />
                            </div>
                        </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="footer-widget footer-menu-widget clearfix">
                                <h4 className="footer-title">Explorer</h4>
                                <div className="footer-menu go-top">
                                    <ul>
                                        <li>
                                            <Link to="/accueil">Accueil</Link>
                                        </li>
                                        <li>
                                            <Link to="/alfaimmo">ALFAIMMO</Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/services">Services</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="footer-widget footer-menu-widget clearfix">
                                <h4 className="footer-title">Biens immobiliers</h4>
                                <div className="footer-menu go-top">
                                    <ul>
                                        <li>
                                            <Link to="/terrains">Terrains</Link>
                                        </li>
                                        <li>
                                            <Link to="/maisons">Maisons</Link>
                                        </li>
                                        <li>
                                            <Link to="/appartements">Appartements</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
                            <div className="footer-widget footer-menu-widget clearfix">
                                <h4 className="footer-title">Coordonnées</h4>
                                <div className="footer-menu go-top">
                                    <ul>
                                        <li className="d-inline-flex align-items-center justify-content-center gap-2">
                                            <div className="footer-address-icon d-flex align-items-center justify-content-center">
                                                <i className="icon-placeholder" />
                                            </div>
                                            <div className="footer-address-info d-flex align-items-center justify-content-center">
                                                <p className="m-0">Grand Mbao Extension villa n°1329 en face stade de Mbao</p>
                                            </div>
                                        </li>
                                        <li className="d-inline-flex align-items-center justify-content-center gap-2">
                                            <div className="footer-address-icon d-flex align-items-center justify-content-center">
                                                <i className="icon-call" />
                                            </div>
                                            <div className="footer-address-info d-flex align-items-center justify-content-center">
                                                <p className="m-0">
                                                    <a href="tel:+221778076565">(+221) 77 807 65 65</a>
                                                </p>
                                            </div>
                                        </li>
                                        <li className="d-inline-flex align-items-center justify-content-center gap-2">
                                            <div className="footer-address-icon d-flex align-items-center justify-content-center">
                                                <i className="icon-mail" />
                                            </div>
                                            <div className="footer-address-info d-flex align-items-center justify-content-center">
                                                <p className="m-0">
                                                    <a href="mailto:commercial@alfaimmobilier.com">commercial@alfaimmobilier.com</a>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </footer>
    );
}

export default Footer_v1;
