import React, { useContext, useState } from "react";

import ResearchDataContext from "../contexts/ResearchDataContext";

/**
 * 
 * @returns 
 */
export const useResearchDataContext = () => useContext(ResearchDataContext);

/**
 * Create the provider component
 *
 * @param param0 
 * @returns 
 */
const ResearchDataProvider = ({ children }) => {

    const [landLocality, _setLandLocality] = useState(null);
    const [landDepartment, _setLandDepartment] = useState(null);
    const [landMunicipality, _setLandMunicipality] = useState(null);
    const [landRegion, _setLandRegion] = useState(null);
    const [landSuperficiesRange, _setLandSuperficiesRange] = useState(null);
    const [serviced, _setServiced] = useState(null);
    const [landPriceRange, _setLandPriceRange] = useState(null);
    
    const [houseCategory, _setHouseCategory] = useState(null);
    const [houseLocality, _setHouseLocality] = useState(null);
    const [houseDepartment, _setHouseDepartment] = useState(null);
    const [houseMunicipality, _setHouseMunicipality] = useState(null);
    const [houseRegion, _setHouseRegion] = useState(null);
    const [houseSuperficiesRange, _setHouseSuperficiesRange] = useState(null);
    const [houseFloorsNumber, _setHouseFloorsNumber] = useState(null);
    const [houseRoomsNumber, _setHouseRoomsNumber] = useState(null);
    const [houseToiletsNumber, _setHouseToiletsNumber] = useState(null);
    const [housePriceRange, _setHousePriceRange] = useState(null);

    const [apartmentCategory, _setApartmentCategory] = useState(null);
    const [apartmentLocality, _setApartmentLocality] = useState(null);
    const [apartmentDepartment, _setApartmentDepartment] = useState(null);
    const [apartmentMunicipality, _setApartmentMunicipality] = useState(null);
    const [apartmentRegion, _setApartmentRegion] = useState(null);
    const [apartmentSuperficiesRange, _setApartmentSuperficiesRange] = useState(null);
    const [apartmentRoomsNumber, _setApartmentRoomsNumber] = useState(null);
    const [apartmentToiletsNumber, _setApartmentToiletsNumber] = useState(null);
    const [apartmentLevel, _setApartmentLevel] = useState(null);
    const [apartmentPriceRange, _setApartmentPriceRange] = useState(null);

    /**
     * 
     * @param {*} locality 
     */
    const setLandLocality = (locality) => {
        _setLandLocality(locality);
    };

    /**
     * 
     * @param {*} department 
     */
    const setLandDepartment = (department) => {
        _setLandDepartment(department);
    };

    /**
     * 
     * @param {*} municipality 
     */
    const setLandMunicipality = (municipality) => {
        _setLandMunicipality(municipality);
    };

    /**
     * 
     * @param {*} region 
     */
    const setLandRegion = (region) => {
        _setLandRegion(region);
    };

    /**
     * 
     * @param {*} superficiesRange 
     */
    const setLandSuperficiesRange = (superficiesRange) => {
        _setLandSuperficiesRange(superficiesRange);
    };

    /**
     * 
     * @param {*} serviced 
     */
    const setServiced = (serviced) => {
        _setServiced(serviced);
    };

    /**
     * 
     * @param {*} priceRange 
     */
    const setLandPriceRange = (priceRange) => {
        _setLandPriceRange(priceRange);
    };

    /**
     * 
     * @param {*} category 
     */
    const setHouseCategory = (category) => {
        _setHouseCategory(category);
    };

    /**
     * 
     * @param {*} locality 
     */
    const setHouseLocality = (locality) => {
        _setHouseLocality(locality);
    };

    /**
     * 
     * @param {*} department 
     */
    const setHouseDepartment = (department) => {
        _setHouseDepartment(department);
    };

    /**
     * 
     * @param {*} municipality 
     */
    const setHouseMunicipality = (municipality) => {
        _setHouseMunicipality(municipality);
    };

    /**
     * 
     * @param {*} region 
     */
    const setHouseRegion = (region) => {
        _setHouseRegion(region);
    };

    /**
     * 
     * @param {*} superficiesRange 
     */
    const setHouseSuperficiesRange = (superficiesRange) => {
        _setHouseSuperficiesRange(superficiesRange);
    };

    /**
     * 
     * @param {*} number 
     */
    const setHouseFloorsNumber = (number) => {
        _setHouseFloorsNumber(number);
    };

    /**
     * 
     * @param {*} number 
     */
    const setHouseRoomsNumber = (number) => {
        _setHouseRoomsNumber(number);
    };

    /**
     * 
     * @param {*} number 
     */
    const setHouseToiletsNumber = (number) => {
        _setHouseToiletsNumber(number);
    };

    /**
     * 
     * @param {*} priceRange 
     */
    const setHousePriceRange = (priceRange) => {
        _setHousePriceRange(priceRange);
    };

    /**
     * 
     * @param {*} category 
     */
    const setApartmentCategory = (category) => {
        _setApartmentCategory(category);
    };

    /**
     * 
     * @param {*} locality 
     */
    const setApartmentLocality = (locality) => {
        _setApartmentLocality(locality);
    };

    /**
     * 
     * @param {*} department 
     */
    const setApartmentDepartment = (department) => {
        _setApartmentDepartment(department);
    };

    /**
     * 
     * @param {*} municipality 
     */
    const setApartmentMunicipality = (municipality) => {
        _setApartmentMunicipality(municipality);
    };

    /**
     * 
     * @param {*} region 
     */
    const setApartmentRegion = (region) => {
        _setApartmentRegion(region);
    };

    /**
     * 
     * @param {*} superficiesRange 
     */
    const setApartmentSuperficiesRange = (superficiesRange) => {
        _setApartmentSuperficiesRange(superficiesRange);
    };

    /**
     * 
     * @param {*} number 
     */
    const setApartmentRoomsNumber = (number) => {
        _setApartmentRoomsNumber(number);
    };

    /**
     * 
     * @param {*} number 
     */
    const setApartmentToiletsNumber = (number) => {
        _setApartmentToiletsNumber(number);
    };

    /**
     * 
     * @param {*} level 
     */
    const setApartmentLevel = (level) => {
        _setApartmentLevel(level);
    };

    /**
     * 
     * @param {*} priceRange 
     */
    const setApartmentPriceRange = (priceRange) => {
        _setApartmentPriceRange(priceRange);
    };

    /**
     * Define the context value
     */
    const contextValue = {
        landLocality,
        landDepartment,
        landMunicipality,
        landRegion,
        landSuperficiesRange,
        serviced,
        landPriceRange,

        houseCategory,
        houseLocality,
        houseDepartment,
        houseMunicipality,
        houseRegion,
        houseSuperficiesRange,
        houseFloorsNumber,
        houseRoomsNumber,
        houseToiletsNumber,
        housePriceRange,

        apartmentCategory,
        apartmentLocality,
        apartmentDepartment,
        apartmentMunicipality,
        apartmentRegion,
        apartmentSuperficiesRange,
        apartmentRoomsNumber,
        apartmentToiletsNumber,
        apartmentLevel,
        apartmentPriceRange,

        setLandLocality,
        setLandDepartment,
        setLandMunicipality,
        setLandRegion,
        setLandSuperficiesRange,
        setServiced,
        setLandPriceRange,

        setHouseCategory,
        setHouseLocality,
        setHouseDepartment,
        setHouseMunicipality,
        setHouseRegion,
        setHouseSuperficiesRange,
        setHouseFloorsNumber,
        setHouseRoomsNumber,
        setHouseToiletsNumber,
        setHousePriceRange,

        setApartmentCategory,
        setApartmentLocality,
        setApartmentDepartment,
        setApartmentMunicipality,
        setApartmentRegion,
        setApartmentSuperficiesRange,
        setApartmentRoomsNumber,
        setApartmentToiletsNumber,
        setApartmentLevel,
        setApartmentPriceRange
    };
    return <ResearchDataContext.Provider value={contextValue}>{children}</ResearchDataContext.Provider>;
};

export default ResearchDataProvider;
