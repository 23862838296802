import React from 'react';
import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import Content from '../components/real-estate-components/land-components/Content';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';
// import { useLocation } from 'react-router-dom';

const Lands = () => {

    // const location = useLocation();
    // let params = location.state;

    return (
        <div>
            <Navbar2 />
            <PageHeader headertitle="Terrains" />
            <Content />
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Lands;
