import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class Newsletter extends Component {
    render() {
        let publicUrl = process.env.PUBLIC_URL + "/";
        let imagealt = "image";

        return (
            <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center--- align-items-center justify-content-center">
			                    {/* <div className="coll-to-info text-color-white">
			                        <h1>Looking for a dream home?</h1>
			                        <p>We can help you realize your dream of a new home</p>
			                    </div>
                                <div className="btn-wrapper go-top">
                                    <Link className="btn btn-effect-3 btn-white" to="/contact">Explore Properties <i className="icon-next" /></Link>
                                </div> */}
			                
                                <div className="footer-widget footer-newsletter-widget my-0">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-lg-6 text-color-white">
                                            <h4 className="footer-title">Newsletter</h4>
                                            <p>
                                                Abonnez-vous à notre newsletter hebdomadaire
                                                et recevez des mises à jour par e-mail
                                            </p>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="footer-newsletter">
                                                <form action="#">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Adresse e-mail *"
                                                    />
                                                    <div className="btn-wrapper">
                                                        <button
                                                            className="theme-btn-4 btn m-0"
                                                            type="submit"
                                                        >
                                                            <i className="fas fa-location-arrow" />
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <h5 className="mt-30">We Accept</h5> */}
                                    {/* <img src={publicUrl+"assets/img/icons/payment-4.png"} alt="Payment Image" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Newsletter;
