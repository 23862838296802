import { createContext } from "react";

/**
 * Create the data context
*/
const ResearchDataContext = createContext({
    landLocality: null,
    landMunicipality: null,
    landDepartment: null,
    landRegion: null,
    serviced: null,
    landPriceRange: null,
    landSuperficiesRange: null,

    houseCategory: null,
    houseLocality: null,
    houseMunicipality: null,
    houseDepartment: null,
    houseRegion: null,
    houseFloorsNumber: null,
    houseRoomsNumber: null,
    houseToiletsNumber: null,
    housePriceRange: null,
    houseSuperficiesRange: null,

    apartmentCategory: null,
    apartmentLocality: null,
    apartmentMunicipality: null,
    apartmentDepartment: null,
    apartmentRegion: null,
    apartmentRoomsNumber: null,
    apartmentToiletsNumber: null,
    apartmentLevel: null,
    apartmentPriceRange: null,
    apartmentSuperficiesRange: null,

    setLandLocality: (locality) => {/* */},
    setLandMunicipality: (municipality) => {/* */},
    setLandDepartment: (department) => {/* */},
    setLandRegion: (region) => {/* */},
    setServiced: (serviced) => {/* */},
    setLandPriceRange: (priceRange) => {/* */},
    setLandSuperficiesRange: (superficiesRange) => {/* */},

    setHouseCategory: (category) => {/* */},
    setHouseLocality: (locality) => {/* */},
    setHouseMunicipality: (municipality) => {/* */},
    setHouseDepartment: (department) => {/* */},
    setHouseRegion: (region) => {/* */},
    setHouseFloorsNumber: (number) => {/* */},
    setHouseRoomsNumber: (number) => {/* */},
    setHouseToiletsNumber: (number) => {/* */},
    setHousePriceRange: (priceRange) => {/* */},
    setHouseSuperficiesRange: (superficiesRange) => {/* */},

    setApartmentCategory: (category) => {/* */},
    setApartmentLocality: (locality) => {/* */},
    setApartmentMunicipality: (municipality) => {/* */},
    setApartmentDepartment: (department) => {/* */},
    setApartmentRegion: (region) => {/* */},
    setApartmentRoomsNumber: (number) => {/* */},
    setApartmentToiletsNumber: (number) => {/* */},
    setApartmentLevel: (level) => {/* */},
    setApartmentPriceRange: (priceRange) => {/* */},
    setApartmentSuperficiesRange: (superficiesRange) => {/* */},
});

export default ResearchDataContext;
