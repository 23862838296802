import React, { useEffect, useState } from "react";

import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../services/AssetHelpers";
import { useDataContext } from "../../../data/providers/DataProvider";
import { useResearchDataContext } from "../providers/ResearchDataProvider";
const { BaseLayer } = LayersControl;

const HousesMap = () => {

    // const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { houses } = useDataContext();
    const {
        houseCategory,
        houseDepartment,
        houseMunicipality,
        houseRegion,
        houseSuperficiesRange,
        houseFloorsNumber,
        houseRoomsNumber,
        houseToiletsNumber,
        housePriceRange
    } = useResearchDataContext();

    const [filteredHouses, setFilteredHouses] = useState([]);

    useEffect(() => {
        let f_houses = houses;

        if(houseCategory){
            f_houses = f_houses.filter((h) => h.real_estate.status === houseCategory);
        }
        if(houseRegion){
            f_houses = f_houses.filter((h) => h.real_estate.region_id === houseRegion.id);
        }
        if(houseMunicipality){
            f_houses = f_houses.filter((h) => h.real_estate.municipality_id === houseMunicipality.id);
        }
        if(houseFloorsNumber){
            f_houses = f_houses.filter((h) => h.floors_number === parseInt(houseFloorsNumber.value));
        }
        if(houseRoomsNumber){
            f_houses = f_houses.filter((h) => h.rooms_number === parseInt(houseRoomsNumber.value));
        }
        if(houseToiletsNumber){
            f_houses = f_houses.filter((h) => h.toilets_number === parseInt(houseToiletsNumber.value));
        }
        if(housePriceRange){
            const housePriceRangeLimits = housePriceRange.split('-');
            f_houses = f_houses.filter((h) => h.real_estate.price >= parseInt(housePriceRangeLimits[0]) && h.real_estate.price <= parseInt(housePriceRangeLimits[1]));
        }
        setFilteredHouses(f_houses);

    }, [houses, houseRegion, houseMunicipality, housePriceRange, houseCategory, houseFloorsNumber, houseRoomsNumber, houseToiletsNumber]);

    return (
        <MapContainer
            className=""
            center={[14.692778, -17.446667]}
            zoom={10}
            scrollWheelZoom={false}
        >
            <LayersControl>
                <BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                </BaseLayer>
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
            </LayersControl>
            {
                filteredHouses.map((house, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[house.real_estate.latitude, house.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{house.real_estate.wording}</h6>
                                    <p>{house.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(house.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {house.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
        </MapContainer>
    );
};

export default HousesMap;
