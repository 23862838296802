import React from 'react';
import Navbar2 from '../components/global-components/Navbar2';
// import PageHeader from '../components/global-components/PageHeader';
import Error from '../components/section-components/error';
import Footer from '../components/global-components/Footer';

const ErrorPage = () => {
    return <div>
        <Navbar2 />
        {/* <PageHeader headertitle="404 Page" /> */}
        <div className='py-4'></div>
        <Error />
        <Footer />
    </div>
}

export default ErrorPage

