import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Sidebar from "./Sidebar";
import { useDataContext } from "../../../data/providers/DataProvider";
import { formatNumber } from "../../../services/AssetHelpers";
import { useResearchDataContext } from "../providers/ResearchDataProvider";
import NoHouse from "./NoHouse";
import usePagination from "../../global-components/hooks/usePagination";
import Pagination from "../../global-components/ui/Pagination";
import { useLoaderSpinnerContext } from "../../global-components/providers/LoaderSpinnerProvider";

const Content = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { houses } = useDataContext();
    const {
        houseCategory,
        houseDepartment,
        houseMunicipality,
        houseRegion,
        houseSuperficiesRange,
        houseFloorsNumber,
        houseRoomsNumber,
        houseToiletsNumber,
        housePriceRange
    } = useResearchDataContext();

    const publicUrl = process.env.PUBLIC_URL + "/";
    const itemsPerPage = [12, 20, 30, 50, 100];
    const sortingElements = [
        {
            value: 'default',
            wording: "Défaut"
        },
        {
            value: 'price-asc',
            wording: "Prix : du plus bas au plus élevé"
        },
        {
            value: 'price-desc',
            wording: "Prix : du plus élevé au plus bas"
        },
    ];

    const [filteredHouses, setFilteredHouses] = useState([]);
    const [searchedText, setSearchText] = useState('');
    const [fromSearch, setFromSearch] = useState(false);

    /**
     * 
     * @param apartment 
     * @returns 
     */
    const checkSearchedText = useCallback((apartment) => {

        return (apartment.real_estate.wording.toLowerCase().indexOf(searchedText) > -1 ||
                apartment.real_estate.description.toLowerCase().indexOf(searchedText) > -1 ||
                apartment.real_estate.superficies.toLowerCase().indexOf(searchedText) > -1 ||
                `${apartment.real_estate.price}`.toLowerCase().indexOf(searchedText) > -1);

    }, [searchedText]);

    useEffect(() => {
        let f_houses = houses;
        setFromSearch(false);

        if(houseCategory){
            f_houses = f_houses.filter((h) => h.real_estate.status === houseCategory);
        }
        if(houseRegion){
            f_houses = f_houses.filter((h) => h.real_estate.region_id === houseRegion.id);
        }
        if(houseMunicipality){
            f_houses = f_houses.filter((h) => h.real_estate.municipality_id === houseMunicipality.id);
        }
        if(houseFloorsNumber){
            f_houses = f_houses.filter((h) => h.floors_number === parseInt(houseFloorsNumber.value));
        }
        if(houseRoomsNumber){
            f_houses = f_houses.filter((h) => h.rooms_number === parseInt(houseRoomsNumber.value));
        }
        if(houseToiletsNumber){
            f_houses = f_houses.filter((h) => h.toilets_number === parseInt(houseToiletsNumber.value));
        }
        if(housePriceRange){
            const housePriceRangeLimits = housePriceRange.split('-');
            f_houses = f_houses.filter((h) => h.real_estate.price >= parseInt(housePriceRangeLimits[0]) && h.real_estate.price <= parseInt(housePriceRangeLimits[1]));
        }
        if(searchedText.length > 0){
            f_houses = f_houses.filter(checkSearchedText);
            setFromSearch(true);
        }
        setFilteredHouses(f_houses);

    }, [
        houses,
        houseRegion,
        houseMunicipality,
        housePriceRange,
        houseCategory,
        houseFloorsNumber,
        houseRoomsNumber,
        houseToiletsNumber,
        searchedText,
        checkSearchedText
    ]);

    const [rowsPerPage, setRowsPerPage] = useState(Number(sessionStorage.getItem('housessPagination')??12));

    useEffect(() => {
        const rows = sessionStorage.getItem('housesPagination');
        setRowsPerPage(rows !== null ? Number(rows) : 12);
    }, []);

    const [curentPageNumber, setCurrentPageNumber] = useState(1);

    const { pageData, pageCount } = usePagination(filteredHouses, curentPageNumber, rowsPerPage);

    useEffect(() => {
        if(curentPageNumber > pageCount){
            setCurrentPageNumber(pageCount);
        }
    }, [pageCount, curentPageNumber]);

    return (
        <div>
            <div className="ltn__product-area ltn__product-gutter">
                <div className="container-fluid px-lg-4 px-xl-5">
                    <div className="row">
                        <div className="col-md-4 col-lg-4 col-xl-3 mb-100">
                            <Sidebar />
                        </div>
                        <div className="col-md-8 col-lg-8 col-xl-9 mb-100">
                            <div className="ltn__shop-options d-flex align-items-center justify-content-between">
                                <ul className="justify-content-start">
                                    <li className="d-none">
                                        <div className="showing-product-number text-right">
                                            <span>Showing 1–12 of 18 results</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="d-flex align-items-center gap-2">
                                           <label>Trier par:</label>
                                            <div className="short-by text-center">
                                                <select className="nice-select">
                                                {
                                                    sortingElements.map((element, index) => {
                                                        return (
                                                            <option key={index} value={element.value}>{element.wording}</option>
                                                        );
                                                    })
                                                }
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="justify-content-start">
                                    <li>
                                        <div className="ltn__grid-list-tab-menu ">
                                            <div className="nav">
                                                <a
                                                    className="active show"
                                                    data-bs-toggle="tab"
                                                    href="#liton_product_grid"
                                                >
                                                    <i className="fas fa-th-large" />
                                                </a>
                                                <a
                                                    data-bs-toggle="tab"
                                                    href="#liton_product_list"
                                                >
                                                    <i className="fas fa-list" />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade active show"
                                    id="liton_product_grid"
                                >
                                    <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* Search Widget */}
                                                <div className="ltn__search-widget mb-30">
                                                    <form action="#">
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Rerchercher..."
                                                            defaultValue=""
                                                            onInput={(e) => setSearchText(e.currentTarget.value)}
                                                        />
                                                        <button type="submit">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                {/* Search Widget */}
                                            </div>
                                            {
                                                !pageData.length
                                                ?
                                                <NoHouse fromSearch={fromSearch} />
                                                :
                                                pageData.map((house, index) => {
                                                    return (
                                                        // {/* ltn__product-item */}
                                                        <div key={index} className="col-12 col-sm-6 col-xxl-4">
                                                        {/* <div className="col-12"> */}
                                                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                                                <div className="product-img go-top">
                                                                    <Link
                                                                        to={`/maison/${house.id}`}
                                                                        onClick={() => setShowLoaderSpinner(true)}
                                                                    >
                                                                        <img
                                                                            // src={publicUrl + "assets/img/product-3/1.jpg"}
                                                                            src={house.real_estate.images[0].data}
                                                                            alt="Illustration"
                                                                        />
                                                                    </Link>
                                                                    <div className="product-badge">
                                                                        <ul>
                                                                        {
                                                                            house.real_estate.status === 'RENT' ?
                                                                            <li className="sale-badge bg-green">À louer</li>
                                                                            :
                                                                            <li className="sale-badge">À vendre</li>
                                                                        }
                                                                        </ul>
                                                                    </div>
                                                                    <div className="product-img-location-gallery">
                                                                        <div className="product-img-location">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to="/contact">
                                                                                        <i className="flaticon-pin" />{" "}
                                                                                        {house.real_estate.municipality.name}
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="real-estate-agent">
                                                                            <div className="agent-img bg-white">
                                                                                <a href="#/team-details">
                                                                                    <img
                                                                                        src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                                        alt="Mascotte ALFAIMMO"
                                                                                        width={32}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="product-img-gallery go-top">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/maison/${house.id}`}>
                                                                                        <i className="fas fa-camera" />{" "}
                                                                                        4
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link to={`/maison/${house.id}`}>
                                                                                        <i className="fas fa-film" />{" "}
                                                                                        2
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-price">
                                                                        <span>
                                                                            {formatNumber(house.real_estate.price)} <label>FCFA</label>
                                                                            {
                                                                                house.real_estate.status === 'RENT' &&
                                                                                <label>/Month</label>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <h2 className="product-title go-top">
                                                                        <Link to={`/maison/${house.id}`}>Maison</Link>
                                                                    </h2>
                                                                    <div className="product-description">
                                                                        <p>{house.real_estate.description}</p>
                                                                    </div>
                                                                    <ul className="ltn__list-item-2 ltn__list-item-2-before ">
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Supercifie:</span>
                                                                            <span>{parseInt(house.real_estate.superficies)} m<sup>2</sup></span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Étages:</span>
                                                                            <span>{house.floors_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Chambres:</span>
                                                                            <span>{house.rooms_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Toilettes:</span>
                                                                            <span>{house.toilets_number}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        // {/* ltn__product-item */}
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="tab-pane fade"
                                    id="liton_product_list"
                                >
                                    <div className="ltn__product-tab-content-inner ltn__product-list-view">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                {/* Search Widget */}
                                                <div className="ltn__search-widget mb-30">
                                                    <form action="#">
                                                        <input
                                                            type="text"
                                                            name="search"
                                                            placeholder="Rerchercher..."
                                                            defaultValue=""
                                                            onInput={(e) => setSearchText(e.currentTarget.value)}
                                                        />
                                                        <button type="submit">
                                                            <i className="fas fa-search" />
                                                        </button>
                                                    </form>
                                                </div>
                                                {/* Search Widget */}
                                            </div>
                                            {
                                                !pageData.length
                                                ?
                                                <NoHouse fromSearch={fromSearch} />
                                                :
                                                pageData.map((house, index) => {
                                                    return (
                                                        // {/* ltn__product-item */}
                                                        <div key={index} className="col-lg-12">
                                                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                                                                <div className="product-img go-top">
                                                                    <Link
                                                                        to={`/maison/${house.id}`}
                                                                        onClick={() => setShowLoaderSpinner(true)}
                                                                    >
                                                                        <img
                                                                            // src={publicUrl + "assets/img/product-3/1.jpg"}
                                                                            src={house.real_estate.images[0].data}
                                                                            alt="Illustration"
                                                                        />
                                                                    </Link>
                                                                    <div className="product-badge">
                                                                        <ul>
                                                                        {
                                                                            house.real_estate.status === 'RENT' ?
                                                                            <li className="sale-badge bg-green">À louer</li>
                                                                            :
                                                                            <li className="sale-badge">À vendre</li>
                                                                        }
                                                                        </ul>
                                                                    </div>
                                                                    <div className="product-img-location-gallery">
                                                                        <div className="product-img-location">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to="/contact">
                                                                                        <i className="flaticon-pin" />{" "}
                                                                                        {house.real_estate.municipality.name}
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="real-estate-agent">
                                                                            <div className="agent-img bg-white">
                                                                                <a href="#/team-details">
                                                                                    <img
                                                                                        src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                                        alt="Mascotte ALFAIMMO"
                                                                                        width={32}
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="product-img-gallery go-top">
                                                                            <ul>
                                                                                <li>
                                                                                    <Link to={`/maison/${house.id}`}>
                                                                                        <i className="fas fa-camera" />{" "}
                                                                                        4
                                                                                    </Link>
                                                                                </li>
                                                                                <li>
                                                                                    <Link to={`/maison/${house.id}`}>
                                                                                        <i className="fas fa-film" />{" "}
                                                                                        2
                                                                                    </Link>
                                                                                </li>
                                                                            </ul>
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="product-info">
                                                                    <div className="product-price">
                                                                        <span>
                                                                            {formatNumber(house.real_estate.price)} <label>FCFA</label>
                                                                            {
                                                                                house.real_estate.status === 'RENT' &&
                                                                                <label>/Month</label>
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <h2 className="product-title go-top">
                                                                        <Link to={`/maison/${house.id}`}>Maison</Link>
                                                                    </h2>
                                                                    <div className="product-description">
                                                                        <p>{house.real_estate.description}</p>
                                                                    </div>
                                                                    <ul className="ltn__list-item-2 ltn__list-item-2-before">
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Supercifie:</span>
                                                                            <span>{parseInt(house.real_estate.superficies)} m<sup>2</sup></span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Étages:</span>
                                                                            <span>{house.floors_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Chambres:</span>
                                                                            <span>{house.rooms_number}</span>
                                                                        </li>
                                                                        <li className="d-inline-flex align-items-center gap-1">
                                                                            <span>Toilettes:</span>
                                                                            <span>{house.toilets_number}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        // {/* ltn__product-item */}
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ltn__pagination-area text-center">
                                <div className="ltn__pagination">
                                    {/* begin::pagination */}
                                    <div className="pagination-box-wrapper mt-5">
                                        <Pagination
                                            id='housesPagination'
                                            pageCount={pageCount}
                                            currentPageNumber={curentPageNumber}
                                            setCurrentPageNumber={setCurrentPageNumber}
                                            rowsPerPage={rowsPerPage}
                                            setRowsPerPage={setRowsPerPage}
                                        />
                                    </div>
                                    {/* end::pagination */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;
