import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useDataContext } from "../../data/providers/DataProvider";
import { formatNumber } from "../../services/AssetHelpers";

const HousesSlider = () => {

    const { houses } = useDataContext();
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div>
            {/* <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 plr--7"> */}
            <div className="ltn__product-slider-area ltn__product-gutter pt-115 pb-90 section-bg-8">
                <div className="container-fluid container-lg">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title-area ltn__section-title-2--- text-center">
                                <h6 className="section-subtitle section-subtitle-2 ltn__secondary-color">Bien immobilier</h6>
                                <h1 className="section-title">Nos Maisons</h1>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row ltn__product-slider-item-four-active-full-width slick-arrow-1"> */}
                    <div className="row ltn__product-slider-item-three-active--- slick-arrow-1">
                    {
                        houses.length > 0
                        ?
                        houses.slice(0, 6).map((house, index) => {
                            return (
                                // {/* ltn__product-item */}
                                <div key={index} className="col-xl-4 col-sm-6 col-12">
                                {/* <div className="col-12"> */}
                                    <div className="ltn__product-item ltn__product-item-4 text-center---">
                                        <div className="product-img go-top">
                                            <Link to={`/maison/${house.id}`}>
                                                <img
                                                    // src={publicUrl + "assets/img/product-3/1.jpg"}
                                                    src={house.real_estate.images[0].data}
                                                    alt="Illustration"
                                                />
                                            </Link>
                                            <div className="product-badge">
                                                <ul>
                                                {
                                                    house.real_estate.status === 'RENT' ?
                                                    <li className="sale-badge bg-green">À louer</li>
                                                    :
                                                    <li className="sale-badge">À vendre</li>
                                                }
                                                </ul>
                                            </div>
                                            <div className="product-img-location-gallery">
                                                <div className="product-img-location">
                                                    <ul>
                                                        <li>
                                                            <Link to="/contact">
                                                                <i className="flaticon-pin" />{" "}
                                                                {house.real_estate.municipality.name}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="real-estate-agent">
                                                    <div className="agent-img bg-white">
                                                        <a href="#/team-details">
                                                            <img
                                                                src={`${publicUrl}assets/img/icons/mascotte-alfaimmo.png`}
                                                                alt="Mascotte ALFAIMMO"
                                                                width={32}
                                                            />
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <div className="product-img-gallery go-top">
                                                    <ul>
                                                        <li>
                                                            <Link to={`/maison/${house.id}`}>
                                                                <i className="fas fa-camera" />{" "}
                                                                4
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={`/maison/${house.id}`}>
                                                                <i className="fas fa-film" />{" "}
                                                                2
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className="product-info">
                                            <div className="product-price">
                                                <span>
                                                {formatNumber(house.real_estate.price)} <label>FCFA</label>
                                                {
                                                    house.real_estate.status === 'RENT' &&
                                                    <label>/Month</label>
                                                }
                                                </span>
                                                {/* {
                                                    house.real_estate.status === 'SALE' && house.real_estate.municipality_id === 432 &&
                                                    <span>{" - "}{formatNumber(house.real_estate.price)} <label>FCFA</label><label>/Month</label></span>
                                                } */}
                                            </div>
                                            <h2 className="product-title go-top">
                                                <Link to={`/maison/${house.id}`}>Maison</Link>
                                            </h2>
                                            <div className="product-description">
                                                <p>{house.real_estate.description}</p>
                                            </div>
                                            <ul className="ltn__list-item-2 ltn__list-item-2-before">
                                                <li className="d-inline-flex align-items-center gap-2">
                                                    <span>Supercifie:</span>
                                                    <span>{house.real_estate.superficies} m<sup>2</sup></span>
                                                </li>
                                                <li className="d-inline-flex align-items-center gap-2">
                                                    <span>Chambres:</span>
                                                    <span>{house.rooms_number}</span>
                                                </li>
                                                <li className="d-inline-flex align-items-center gap-2">
                                                    <span>Toilettes:</span>
                                                    <span>{house.toilets_number}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                // {/* ltn__product-item */}
                            );
                        })
                        :
                        <div className="d-flex align-items-center justify-content-center py-5">
                            <div className="w-100 d-flex align-items-center justify-content-center alert alert-primary">
                                <p className="m-0">Aucune maison disponible pour le moment</p>
                            </div>
                        </div>
                    }
                    </div>
                </div>
                <div className="btn-wrapper text-center go-top">
                    <Link to="/maisons" className="btn theme-btn-1 btn-effect-1 text-uppercase">Voir tout</Link>
                </div>
            </div>
        </div>
    );
};

export default HousesSlider;
