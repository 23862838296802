import { createContext } from "react";

/**
 * Create the data context
*/
const DataContext = createContext({
    lands: [],
    houses: [],
    apartments: [],
    realEstates: [],

    municipalities: [],
    // departments: [],
    regions: [],

    fetchData: () => {/* */},
    fetchRealEstates: () => {/* */},
    fetchLands: () => {/* */},
    fetchHouses: () => {/* */},
    fetchApartments: () => {/* */},

    fetchMunicipalities:() => {/* */},
    // fetchDepartments:() => {/* */},
    fetchRegions:() => {/* */},

    setRealEstates: ([]) => {/* */},
    setLands: ([]) => {/* */},
    setHouses: ([]) => {/* */},
    setApartments: ([]) => {/* */},

    setMunicipalities: () => {/* */},
    // setDepartments: () => {/* */},
    setRegions: () => {/* */},
});

export default DataContext;
