import React from 'react';
import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import Content from '../components/real-estate-components/house-components/Content';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';

const Houses = () => {
    return (
        <div>
            <Navbar2 />
            <PageHeader headertitle="Maisons" />
            <Content />
            <Newsletter />
            <Footer />
        </div>
    );
}

export default Houses;
