import React from 'react';
import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';
import TestAzureMap from '../components/real-estate-components/maps/TestAzureMap';

const MyAzureMap = () => {

    return (
        <div>
            <Navbar2 />
            <PageHeader
                headertitle="Localisation des biens immobiliers"
                customclass="mb-0"
            />
            <div className='container-fluid mt-120 mb-120'>
                <TestAzureMap />
            </div>
            <Footer />
        </div>
    );
};

export default MyAzureMap;
