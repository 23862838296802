import React from 'react';

const Map = () => {

    return (
        <div className="google-map mb-120">
            <iframe
                title='#'
                src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3667.740621776024!2d-17.321206925124585!3d14.73156638577049!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sGrand%20Mbao%20Extension%20villa%20n%C2%B01329%20en%20face%20stade%20de%20Mbao!5e1!3m2!1sfr!2ssn!4v1731574138915!5m2!1sfr!2ssn"
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3858.4816088302005!2d-17.473826225124387!3d14.741875385761434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec10db7810892c9%3A0x5b62fbb4fd8d0fb7!2sCabinet%20Carr%C3%A9e!5e0!3m2!1sfr!2ssn!4v1730370216994!5m2!1sfr!2ssn"
                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.567046491507!2d-17.473826225124387!3d14.741875385761434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec10db7810892c9%3A0x5b62fbb4fd8d0fb7!2sCabinet%20Carr%C3%A9e!5e1!3m2!1sfr!2ssn!4v1730369796166!5m2!1sfr!2ssn"
                width="100%"
                height="100%"
                style={{border:0}}
                allowfullscreen
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
            />
		</div>
    );
};

export default Map
