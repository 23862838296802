import React, { useEffect } from "react";

const ContactForm = () => {

    useEffect(() => {
        const $ = window.$;

        // Get the form.
        var form = $("#contact-form");

        // Get the messages div.
        var formMessages = $(".form-messege");

        // Set up an event listener for the contact form.
        $(form).submit(function (e) {
            // Stop the browser from submitting the form.
            e.preventDefault();

            // Serialize the form data.
            var formData = $(form).serialize();

            // Submit the form using AJAX.
            $.ajax({
                type: "POST",
                url: $(form).attr("action"),
                data: formData,
            })
            .done(function (response) {
                // Make sure that the formMessages div has the 'success' class.
                $(formMessages).removeClass("error");
                $(formMessages).addClass("success");

                // Set the message text.
                $(formMessages).text(response);

                // Clear the form.
                $("#contact-form input,#contact-form textarea").val("");
            })
            .fail(function (data) {
                // Make sure that the formMessages div has the 'error' class.
                $(formMessages).removeClass("success");
                $(formMessages).addClass("error");

                // Set the message text.
                if (data.responseText !== "") {
                    $(formMessages).text(data.responseText);
                } else {
                    $(formMessages).text("Oops! An error occured and your message could not be sent.");
                }
            });
        });
    }, []);

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div className="ltn__contact-message-area mb-60">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="ltn__form-box contact-form-box box-shadow white-bg">
                            <h4 className="title-2">Nous écrire</h4>
                            <form
                                id="contact-form"
                                action={publicUrl + "mail.php"}
                                method="post"
                            >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-item-name ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Prénom et nom"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-email ltn__custom-icon">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Adresse e-mail"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item">
                                            <select
                                                className="nice-select"
                                                name="service"
                                            >
                                                <option>
                                                    Sélectionnez un type de
                                                    service
                                                </option>
                                                <option>
                                                    Gestion immobilière
                                                </option>
                                                <option>
                                                    Service hypothécaire
                                                </option>
                                                <option>
                                                    Service de conseil
                                                </option>
                                                <option>
                                                    Achat de maison
                                                </option>
                                                <option>
                                                    Vente de maison
                                                </option>
                                                <option>
                                                    Services d'entiercement
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-item input-item-phone ltn__custom-icon">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="Numéro de téléphone"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="input-item input-item-textarea ltn__custom-icon">
                                    <textarea
                                        name="message"
                                        placeholder="Message"
                                        defaultValue={""}
                                    />
                                </div>
                                {/* <p>
                                    <label className="input-info-save mb-0"><input type="checkbox" name="agree" /> Save my name, email, and website in this browser for the next time I comment.</label>
                                </p> */}
                                <div className="btn-wrapper mt-0">
                                    <button className="btn theme-btn-1 btn-effect-1 text-uppercase" type="submit">Soumettre</button>
                                </div>
                                <p className="form-messege mb-0 mt-20" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
