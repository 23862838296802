import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import DataProvider from './data/providers/DataProvider';
import ResearchDataProvider from './components/real-estate-components/providers/ResearchDataProvider';

import Home from './pages/Home';
import AlfaImmo from './pages/Alfaimmo';
import Contact from './pages/Contact';
import Houses from './pages/Houses';
import ErrorPage from './pages/404';
import Lands from './pages/Lands';
import Apartments from './pages/Apartments';
import House from './pages/House';
import Land from './pages/Land';
import Apartment from './pages/Apartment';
import RealEstatesLocations from './pages/RealEstatesLocations';
import LoaderSpinnerProvider from './components/global-components/providers/LoaderSpinnerProvider';
import MyAzureMap from './pages/MyAzureMap';

const Root = () => {
    return(
        <BrowserRouter basename="/">
            <Routes>
                <Route exact path="/" element={<Home />} />

                <Route path="/azure-map" element={<MyAzureMap />} />
                <Route path="/accueil" element={<Home />} />
                <Route path="/alfaimmo" element={<AlfaImmo />} />
                <Route path="/contact" element={<Contact />} />

                <Route path="/maisons" element={<Houses />} />
                <Route path="/terrains" element={<Lands />} />
                <Route path="/appartements" element={<Apartments />} />

                <Route path="/maison/:id" element={<House />} />
                <Route path="/terrain/:id" element={<Land />} />
                <Route path="/appartement/:id" element={<Apartment />} />

                <Route path="/localisation-des-biens-immobiliers" element={<RealEstatesLocations />} />

                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Root;

ReactDOM.render(
    <LoaderSpinnerProvider>
        <DataProvider>
            <ResearchDataProvider>
                <Root />
            </ResearchDataProvider>
        </DataProvider>
    </LoaderSpinnerProvider>,
    document.getElementById('quarter')
);
