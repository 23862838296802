import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CopyRight extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

        return (
			<div className="ltn__copyright-area ltn__copyright-2 section-bg-7 py-0 plr--5">
                <div className="container container-md-fluid container-xl ltn__border-top-2">
                    <div className="row gy-3">
                        <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                            <div className="ltn__copyright-design clearfix">
                                <p>Tous droits reservés © ALFAIMMO <span className="current-year" /></p>
                            </div>
                        </div>
                        <div className="d-none d-md-block col-12 col-md-2 col-lg-2 col-xl-4"></div>
                        {/* <div className="col-lg-4 col-12 align-self-center">
                            <div className="ltn__copyright-menu text-center mt-0">
                                <ul className="go-top">
                                    <li><Link to="/about">Conditions générales</Link></li>
                                    <li><Link to="/about">Réclamation</Link></li>
                                    <li><Link to="/about">Politique de confidentialité</Link></li>
                                </ul>
                            </div>
                        </div> */}
                        <div className="col-12 col-md-5 col-lg-5 col-xl-4">
                            <div className="text-md-end">
                                <p>Développé par <a href="https://carreemarketing.com" target="_blank" rel="noopener noreferrer">Carrée Marketing</a></p>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
        )
    }
}

export default CopyRight
