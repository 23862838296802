import { createContext } from "react";

/**
 * Create the context
 */
const LoaderSpinnerContext = createContext({
    show: true,
    setShow: () => {/* */}
});

export default LoaderSpinnerContext;
