import React, { useEffect, useState } from "react";

import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../services/AssetHelpers";
import { useDataContext } from "../../../data/providers/DataProvider";
import { useResearchDataContext } from "../providers/ResearchDataProvider";
const { BaseLayer } = LayersControl;

const ApartmentsMap = () => {

    // const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { apartments } = useDataContext();
    const {
        apartmentCategory,
        apartmentDepartment,
        apartmentMunicipality,
        apartmentRegion,
        apartmentSuperficiesRange,
        apartmentLevel,
        apartmentRoomsNumber,
        apartmentToiletsNumber,
        apartmentPriceRange
    } = useResearchDataContext();

    const [filteredAppartements, setFilteredAppartements] = useState([]);

    useEffect(() => {
        let f_apartments = apartments;

        if(apartmentCategory){
            f_apartments = f_apartments.filter((a) => a.real_estate.status === apartmentCategory);
        }
        if(apartmentRegion){
            f_apartments = f_apartments.filter((a) => a.real_estate.region_id === apartmentRegion.id);
        }
        if(apartmentMunicipality){
            f_apartments = f_apartments.filter((a) => a.real_estate.municipality_id === apartmentMunicipality.id);
        }
        if(apartmentLevel){
            f_apartments = f_apartments.filter((a) => parseInt(a.level) === parseInt(apartmentLevel.value));
        }
        if(apartmentRoomsNumber){
            f_apartments = f_apartments.filter((a) => a.rooms_number === parseInt(apartmentRoomsNumber.value));
        }
        if(apartmentToiletsNumber){
            f_apartments = f_apartments.filter((a) => a.toilets_number === parseInt(apartmentToiletsNumber.value));
        }
        if(apartmentPriceRange){
            const apartmentPriceRangeLimits = apartmentPriceRange.split('-');
            f_apartments = f_apartments.filter((a) => a.real_estate.price >= parseInt(apartmentPriceRangeLimits[0]) && a.real_estate.price <= parseInt(apartmentPriceRangeLimits[1]));
        }
        setFilteredAppartements(f_apartments);

    }, [apartments, apartmentRegion, apartmentMunicipality, apartmentPriceRange, apartmentCategory, apartmentLevel, apartmentRoomsNumber, apartmentToiletsNumber]);

    return (
        <MapContainer
            className=""
            center={[14.692778, -17.446667]}
            zoom={10}
            scrollWheelZoom={false}
        >
            <LayersControl>
                <BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                </BaseLayer>
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
            </LayersControl>
            {
                filteredAppartements.map((apartment, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[apartment.real_estate.latitude, apartment.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{apartment.real_estate.wording}</h6>
                                    <p>{apartment.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(apartment.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {apartment.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
        </MapContainer>
    );
};

export default ApartmentsMap;
