import React, { useEffect, useRef, useState } from "react";
import { useDataContext } from "../../../data/providers/DataProvider";
import { useResearchDataContext } from "../providers/ResearchDataProvider";

import Select from 'react-select';

import {
    customSelectClearIndicator,
    customSelectControl,
    customSelectDropdownIndicator,
    customSelectMenu,
    customSelectMenuList,
    customSelectOption,
    customSelectPlaceholder,
    customSelectStyles,
    customSelectValueContainer
} from "../../global-components/select-customizer";
import RangeSlider from "../../global-components/ui/RangeSlider";


const Sidebar = () => {

    const researchData = useResearchDataContext();
    const {houses, regions, municipalities} = useDataContext();

    const regionsSelectRef = useRef(null);
    const municipalitiesSelectRef = useRef(null);
    const floorsNumberSelectRef = useRef(null);
    const roomsNumberSelectRef = useRef(null);
    const toiletsNumberSelectRef = useRef(null);
    const priceRangeInputRef = useRef(null);
    const priceRangeTextInputRef = useRef(null);
    const [municipalitiesByRegion, setMunicipalitiesByRegion] = useState([]);
    const [disableMunicipalitiesSelect, setDisableMunicipalitiesSelect] = useState(true);

    const [priceRangeLimits, setPriceRangeLimits] = useState([]);

    useEffect(() => {
        if(regionsSelectRef.current){
            regionsSelectRef.current.setValue(researchData.houseRegion);
        }
        if(municipalitiesSelectRef.current){
            municipalitiesSelectRef.current.setValue(researchData.houseMunicipality);
        }
        if(floorsNumberSelectRef.current){
            floorsNumberSelectRef.current.setValue(researchData.houseFloorsNumber);
        }
        if(roomsNumberSelectRef.current){
            roomsNumberSelectRef.current.setValue(researchData.houseRoomsNumber);
        }
        if(toiletsNumberSelectRef.current){
            toiletsNumberSelectRef.current.setValue(researchData.houseToiletsNumber);
        }
        if(researchData.housePriceRange){
            setPriceRangeLimits(researchData.housePriceRange.split('-'));
            // console.log(priceRangeLimits)
        }
        // if(priceRangeInputRef.current){
        //     priceRangeInputRef.current.value = researchData.housePriceRange;
        // }
        // if(priceRangeTextInputRef.current && researchData.housePriceRange){
        //     const priceRangeLimits = researchData.housePriceRange.split('-');
        //     priceRangeTextInputRef.current.value = `${priceRangeLimits[0]} FCFA - ${priceRangeLimits[1]} FCFA`;
        // }
    }, [
        researchData.houseFloorsNumber,
        researchData.houseMunicipality,
        researchData.housePriceRange,
        researchData.houseRegion,
        researchData.houseRoomsNumber,
        researchData.houseToiletsNumber
    ]);

    /**
     * 
     * @param region 
     */
    const changeRegion = (region) => {
        researchData.setHouseRegion(region);

        if(region === null || region === undefined){
            setMunicipalitiesByRegion([]);
            municipalitiesSelectRef.current.clearValue();
            setDisableMunicipalitiesSelect(true);
            // setIsValidComplainantRegion(false);
        }
        else{
            setMunicipalitiesByRegion(municipalities.filter((municipality) => municipality.region_id === region.id));
            setDisableMunicipalitiesSelect(false);

            if(researchData.houseMunicipality !== null &&
                    researchData.houseMunicipality !== null &&
                researchData.houseMunicipality?.region_id !== region.id){
                    municipalitiesSelectRef.current.clearValue();
            }
            // setIsValidComplainantRegion(true);
        }
    };

    return (
        <aside className="sidebar ltn__shop-sidebar">
            {/* <h3 className="mb-10">Filtres</h3> */}
            {/* <label className="mb-30">
                <small>About 9,620 results (0.62 seconds) </small>
            </label> */}
            {/* Advance Information widget */}
            <div className="widget ltn__menu-widget px-3">
                <h4 className="ltn__widget-title">Catégorie</h4>
                <ul>
                    <li>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="house_status"
                                id="houseToRent"
                                value="RENT"
                                checked={researchData.houseCategory === 'RENT'}
                                onChange={(e) => researchData.setHouseCategory('RENT')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="houseToRent"
                            >
                                À louer
                            </label>
                        </div>
                        <span className="category-count">{houses.filter((h) => h.real_estate.status === 'RENT').length}</span>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="house_status"
                                id="houseToSale"
                                value="SALE"
                                checked={researchData.houseCategory === 'SALE'}
                                onChange={(e) => researchData.setHouseCategory('SALE')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="houseToSale"
                            >
                                À vendre
                            </label>
                        </div>
                        <span className="category-count">{houses.filter((h) => h.real_estate.status === 'SALE').length}</span>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="house_status"
                                id="houseToRentAndToSale"
                                value=""
                                checked={researchData.houseCategory === null}
                                onChange={(e) => researchData.setHouseCategory(null)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="houseToRentAndToSale"
                            >
                                Tout
                            </label>
                        </div>
                        <span className="category-count">{houses.length}</span>
                    </li>
                </ul>
                <div className="py-3"></div>
                <Select
                    ref={regionsSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='region'
                    options={regions}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    placeholder='Région'
                    styles={customSelectStyles}
                    onChange={changeRegion}
                />
                <div className="py-3"></div>
                <Select
                    ref={municipalitiesSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isDisabled={disableMunicipalitiesSelect}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='municipality'
                    options={municipalitiesByRegion}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    placeholder='Commune'
                    styles={customSelectStyles}
                    onChange={researchData.setHouseMunicipality}
                />
                <div className="py-3"></div>
                <Select
                    ref={floorsNumberSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='floors_numbers'
                    options={[
                        {wording: "1", value: "1"},
                        {wording: "2", value: "2"},
                        {wording: "3", value: "3"},
                        {wording: "4", value: "4"},
                        {wording: "5", value: "5"},
                        {wording: "6", value: "6"},
                        {wording: "7 et plus", value: "7+"}
                    ]}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.wording}
                    placeholder="Nombre d'étages"
                    styles={customSelectStyles}
                    onChange={researchData.setHouseFloorsNumber}
                />
                <div className="py-3"></div>
                <Select
                    ref={roomsNumberSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='rooms_number'
                    options={[
                        {wording: "1", value: "1"},
                        {wording: "2", value: "2"},
                        {wording: "3", value: "3"},
                        {wording: "4 et plus", value: "4+"}
                    ]}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.wording}
                    placeholder='Nombre de chambres'
                    styles={customSelectStyles}
                    onChange={researchData.setHouseRoomsNumber}
                />
                <div className="py-3"></div>
                <Select
                    ref={toiletsNumberSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='toilets_number'
                    options={[
                        {wording: "1", value: "1"},
                        {wording: "2", value: "2"},
                        {wording: "3 et plus", value: "3+"}
                    ]}
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.wording}
                    placeholder='Nombre de toilettes'
                    styles={customSelectStyles}
                    onChange={researchData.setHouseToiletsNumber}
                />
                <div className="py-3"></div>
                {/* Price Filter Widget */}
                <div className="widget--- ltn__price-filter-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border---">Filtrer par prix</h4>
                    <div className="price_filter">
                        {/* <div className="price_slider_amount d-flex align-items-center gap-2">
                            <input
                                type="text"
                                className="amount mb-4"
                                name="price_range_to_display"
                                placeholder="Fourchette de prix"
                                ref={priceRangeTextInputRef}
                            />
                            <input
                                type="hidden"
                                className="price-range"
                                name="price_range"
                                placeholder=""
                                ref={priceRangeInputRef}
                            />
                        </div>
                        <div className="slider-range" /> */}
                        <div className="position-relative">
                            <RangeSlider
                                min={125000}
                                max={15200055}
                                onChange={({ min, max }) => {
                                    // console.log(`min = ${min}, max = ${max}`)
                                    researchData.setHousePriceRange(`${min}-${max}`);
                                }}
                                // leftSliderCurrentValue={priceRangeLimits ? parseInt(priceRangeLimits[0]) : 125000}
                                // rightSliderCurrentValue={priceRangeLimits ? parseInt(priceRangeLimits[1]) : 4500000}
                                leftSliderCurrentValue={researchData.housePriceRange ? parseInt(researchData.housePriceRange.split('-')[0]) : 125000}
                                rightSliderCurrentValue={researchData.housePriceRange ? parseInt(researchData.housePriceRange.split('-')[1]) : 4500000}
                            />
                        </div>
                    </div>
                </div>
                {/* Price Filter Widget */}
            </div>
        </aside>
    );
}

export default Sidebar;
