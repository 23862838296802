import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDataContext } from "../../../data/providers/DataProvider";
import { useResearchDataContext } from "../providers/ResearchDataProvider";

import Select from 'react-select';

import {
    customSelectClearIndicator,
    customSelectControl,
    customSelectDropdownIndicator,
    customSelectMenu,
    customSelectMenuList,
    customSelectOption,
    customSelectPlaceholder,
    customSelectStyles,
    customSelectValueContainer
} from "../../global-components/select-customizer";
import RangeSlider from "../../global-components/ui/RangeSlider";


const Sidebar = () => {

    const researchData = useResearchDataContext();
    const {lands, regions, municipalities} = useDataContext();

    const regionsSelectRef = useRef(null);
    const municipalitiesSelectRef = useRef(null);
    const priceRangeInputRef = useRef(null);
    const priceRangeTextInputRef = useRef(null);
    const [municipalitiesByRegion, setMunicipalitiesByRegion] = useState([]);
    const [disableMunicipalitiesSelect, setDisableMunicipalitiesSelect] = useState(true);

    const [priceRangeLimits, setPriceRangeLimits] = useState([]);

    useEffect(() => {
        if(regionsSelectRef.current){
            regionsSelectRef.current.setValue(researchData.landRegion);
        }
        if(municipalitiesSelectRef.current){
            municipalitiesSelectRef.current.setValue(researchData.landMunicipality);
        }
        if(researchData.landPriceRange){
            setPriceRangeLimits(researchData.landPriceRange.split('-'));
            // console.log(priceRangeLimits)
        }
        // if(priceRangeInputRef.current){
        //     priceRangeInputRef.current.value = researchData.landPriceRange;
        // }
        // if(priceRangeTextInputRef.current && researchData.landPriceRange){
        //     const priceRangeLimits = researchData.landPriceRange.split('-');
        //     priceRangeTextInputRef.current.value = `${priceRangeLimits[0]} FCFA - ${priceRangeLimits[1]} FCFA`;
        // }
    }, [researchData.landMunicipality, researchData.landPriceRange, researchData.landRegion]);

    /**
     * 
     * @param region 
     */
    const changeRegion = (region) => {
        researchData.setLandRegion(region);

        if(region === null || region === undefined){
            setMunicipalitiesByRegion([]);
            municipalitiesSelectRef.current.clearValue();
            setDisableMunicipalitiesSelect(true);
            // setIsValidComplainantRegion(false);
        }
        else{
            setMunicipalitiesByRegion(municipalities.filter((municipality) => municipality.region_id === region.id));
            setDisableMunicipalitiesSelect(false);

            if(researchData.landMunicipality !== null &&
                researchData.landMunicipality !== null &&
                researchData.landMunicipality?.region_id !== region.id){
                    municipalitiesSelectRef.current.clearValue();
            }
            // setIsValidComplainantRegion(true);
        }
    };

    return (
        <aside className="sidebar ltn__shop-sidebar">
            {/* <h3 className="pt-4 pb-2">Filtres</h3> */}
            {/* <label className="mb-30">
                <small>About 9,620 results (0.62 seconds) </small>
            </label> */}
            {/* Advance Information widget */}
            <div className="widget ltn__menu-widget px-3">
                <h4 className="ltn__widget-title">Viabilisé</h4>
                <ul>
                    <li>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="serviced"
                                id="serviced"
                                value="YES"
                                checked={researchData.serviced === 'YES'}
                                onChange={(e) => researchData.setServiced('YES')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="serviced"
                            >
                                Oui
                            </label>
                        </div>
                        <span className="serviced-count">{lands.filter((l) => l.serviced === 'YES').length}</span>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="serviced"
                                id="notServiced"
                                value="NO"
                                checked={researchData.serviced === 'NO'}
                                onChange={(e) => researchData.setServiced('NO')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="notServiced"
                            >
                                Non
                            </label>
                        </div>
                        <span className="unserviced-count">{lands.filter((l) => l.serviced === 'NO').length}</span>
                    </li>
                    <li>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="serviced"
                                id="servicedAndNotServiced"
                                value=""
                                checked={researchData.serviced === null}
                                onChange={(e) => researchData.setServiced(null)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="servicedAndNotServiced"
                            >
                                Tout
                            </label>
                        </div>
                        <span className="unserviced-count">{lands.length}</span>
                    </li>
                </ul>
                <div className="py-3"></div>
                <Select
                    ref={regionsSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='region'
                    options={regions}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    placeholder='Région'
                    styles={customSelectStyles}
                    onChange={changeRegion}
                />
                <div className="py-3"></div>
                <Select
                    ref={municipalitiesSelectRef}
                    components={{
                        Control: customSelectControl,
                        ClearIndicator: customSelectClearIndicator,
                        DropdownIndicator: customSelectDropdownIndicator,
                        Menu: customSelectMenu,
                        MenuList: customSelectMenuList,
                        Option: customSelectOption,
                        Placeholder: customSelectPlaceholder,
                        ValueContainer: customSelectValueContainer
                    }}
                    isClearable={true}
                    isSearchable={true}
                    isDisabled={disableMunicipalitiesSelect}
                    menuPlacement='auto'
                    // menuPortalTarget={document.body}
                    name='municipality'
                    options={municipalitiesByRegion}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    placeholder='Commune'
                    styles={customSelectStyles}
                    onChange={researchData.setLandMunicipality}
                />
                <div className="py-3"></div>
                {/* Price Filter Widget */}
                <div className="widget--- ltn__price-filter-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border---">Filtrer par prix</h4>
                    <div className="price_filter">
                        {/* <div className="price_slider_amount d-flex align-items-center gap-2">
                            <input
                                type="text"
                                className="amount mb-4"
                                name="price_range_to_display"
                                placeholder="Fourchette de prix"
                                ref={priceRangeTextInputRef}
                            />
                            <input
                                type="hidden"
                                className="price-range"
                                name="price_range"
                                placeholder=""
                                ref={priceRangeInputRef}
                            />
                        </div>
                        <div className="slider-range" /> */}
                        <div className="position-relative">
                            <RangeSlider
                                min={125000}
                                max={15200055}
                                onChange={({ min, max }) => {
                                    // console.log(`min = ${min}, max = ${max}`)
                                    researchData.setLandPriceRange(`${min}-${max}`);
                                }}
                                leftSliderCurrentValue={researchData.landPriceRange ? parseInt(researchData.landPriceRange.split('-')[0]) : 125000}
                                rightSliderCurrentValue={researchData.landPriceRange ? parseInt(researchData.landPriceRange.split('-')[1]) : 4500000}
                            />
                        </div>
                    </div>
                </div>
                {/* Price Filter Widget */}
            </div>
        </aside>
    );
}

export default Sidebar;
