import React, { useEffect, useState } from "react";

import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../services/AssetHelpers";
import { useDataContext } from "../../../data/providers/DataProvider";
import { useResearchDataContext } from "../providers/ResearchDataProvider";
const { BaseLayer } = LayersControl;

const LandsMap = () => {

    // const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { lands } = useDataContext();
    const {
        landDepartment,
        landMunicipality,
        landRegion,
        landSuperficiesRange,
        landPriceRange,
        serviced
    } = useResearchDataContext();

    const [filteredLands, setFilteredLands] = useState([]);

    useEffect(() => {
        let f_lands = lands;

        if(landRegion){
            f_lands = f_lands.filter((l) => l.real_estate.region_id === landRegion.id);
        }
        if(landMunicipality){
            f_lands = f_lands.filter((l) => l.real_estate.municipality_id === landMunicipality.id);
        }
        if(serviced){
            f_lands = f_lands.filter((l) => l.serviced === serviced);
        }
        if(landPriceRange){
            const landPriceRangeLimits = landPriceRange.split('-');
            f_lands = f_lands.filter((l) => l.real_estate.price >= parseInt(landPriceRangeLimits[0]) && l.real_estate.price <= parseInt(landPriceRangeLimits[1]));
        }
        setFilteredLands(f_lands);

    }, [landRegion, landMunicipality, landSuperficiesRange, landPriceRange, lands, serviced]);

    return (
        <MapContainer
            className=""
            // center={[14.692778, -17.446667]}
            center={[14.232438, -14.545898]}
            zoom={7}
            scrollWheelZoom={false}
        >
            {/* <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            /> */}
            <LayersControl>
                <BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                </BaseLayer>
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="ArcGis">
                    <TileLayer
                        url='http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
                        attribution='&copy;<a href="http://www.esri.com/">Esri</a>'
                        // maxZoom= {20}
                        // subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
            </LayersControl>
            {
                filteredLands.map((land, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[land.real_estate.latitude, land.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{land.real_estate.wording}</h6>
                                    <p>{land.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(land.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {land.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
        </MapContainer>
    );
};

export default LandsMap;
