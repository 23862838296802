import React from "react";

import LandSearch from "../real-estate-components/land-components/LandSearch";
import HouseSearch from "../real-estate-components/house-components/HouseSearch";
import ApartmentSearch from "../real-estate-components/apartment-components/ApartmentSearch";

const Banner = () => {

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div className="ltn__slider-area ltn__slider-4">
            <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">

                {/* <video id="mainBannerVideo" className="" autoPlay muted loop>
					<source src={publicUrl+"assets/media/3.mp4"} type="video/mp4" />
					<source src={publicUrl + "assets/media/DJI_0434.mov"} type="video/mp4" />
				</video> */}
                <div
                    className="bg-overlay-theme-black-50 position-absolute bg-image top-0 bottom-0 start-0 end-0 w-100 h-100"
                    data-bs-bg={`${publicUrl}assets/img/bg/img-6.jpg`}
                    style={{zIndex: -1}}
                ></div>
                {/* ltn__slide-item */}
                {/* <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-30" data-bs-bg={publicUrl+"assets/img/slider/41.jpg"}> */}
                <div
                    className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-4 bg-image bg-overlay-theme-black-0"
                    // data-bs-bg={publicUrl + "assets/img/bg/img.jpg"}
                >
                    <div className="ltn__slide-item-inner text-left">
                        <div className="container-fluid container-lg">
                            <div className="row">
                                <div className="col-lg-12 align-self-center">
                                    <div className="slide-item-car-dealer-form">
                                        <div className="ltn__car-dealer-form-tab">
                                            <div className="ltn__tab-menu  text-uppercase">
                                                <div className="nav">
                                                    <a
                                                        className="active show"
                                                        data-bs-toggle="tab"
                                                        href="#ltn__form_tab_1_1"
                                                    >
                                                        <i className="fas fa-sign-hanging" />
                                                        Terrain
                                                    </a>
                                                    <a
                                                        data-bs-toggle="tab"
                                                        href="#ltn__form_tab_1_2"
                                                    >
                                                        <i className="fas fa-home" />
                                                        Maison
                                                    </a>
                                                    <a
                                                        data-bs-toggle="tab"
                                                        href="#ltn__form_tab_1_3"
                                                    >
                                                        <i className="fas fa-building" />
                                                        Appartement
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="ltn__form_tab_1_1"
                                                >
                                                    <LandSearch />
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="ltn__form_tab_1_2"
                                                >
                                                    <HouseSearch />
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="ltn__form_tab_1_3"
                                                >
                                                    <ApartmentSearch />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__slide-item */}
            </div>
        </div>
    );
};

export default Banner;
