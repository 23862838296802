import React from "react";

const ContactInfo = () => {

    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div className="ltn__contact-address-area mb-90">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow personalized">
                            <div className="ltn__contact-address-icon">
                                <img
                                    src={`${publicUrl}assets/img/icons/10.png`}
                                    alt="Icône"
                                />
                            </div>
                            <h3>Adresse e-mail</h3>
                            <p>commercial@alfaimmobilier.com</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow personalized">
                            <div className="ltn__contact-address-icon">
                                <img
                                    src={`${publicUrl}assets/img/icons/11.png`}
                                    alt="Icône"
                                    />
                            </div>
                            <h3>Numéro de téléphone</h3>
                            <p>(+221) 77 807 65 65</p>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow personalized">
                            <div className="ltn__contact-address-icon">
                                <img
                                    src={`${publicUrl}assets/img/icons/12.png`}
                                    alt="Icône"
                                />
                            </div>
                            <h3>Adresse du bureau</h3>
                            <p>Grand Mbao Extension villa n°1329 en face stade de Mbao</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactInfo;
