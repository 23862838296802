import React from 'react';

const HomeVideo = () => {

    let publicUrl = process.env.PUBLIC_URL+'/'
    let imagealt = 'image'

    return (
        <div className="ltn__video-popup-area ltn__video-popup-margin---">
            <div className="ltn__video-bg-img ltn__video-popup-height-600--- bg-overlay-black-40 bg-image bg-fixed ltn__animation-pulse1" data-bs-bg={publicUrl+"assets/img/bg/img1.jpg"}>
            <a
                className="ltn__video-icon-2 ltn__video-icon-2-border---"
                href="#homeVideo"
                data-rel="lightcase:myCollection"
                // onClick={() => {
                //     videoRef.current.className = 'w-100 d-block'
                // }}
            >
                <i className="fa fa-play" />
            </a>
            <div id="homeVideo">
            <video className="w-100" width="600" height="380" controls>
                <source src={publicUrl + "assets/media/DJI_0434.mov"} type="video/mp4"/>
                {/* <source src="movie.ogg" type="video/ogg"/> */}
                Your browser does not support the video tag.
            </video>
            </div>
            </div>
        </div>
    );
};

export default HomeVideo;
