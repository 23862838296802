import React from 'react';
import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';
import RealEstatesMap from '../components/real-estate-components/maps/RealEstatesMap';
import RealEstatesMapSidebar from '../components/real-estate-components/maps/RealEstatesMapSidebar';
import LandsMapSidebar from '../components/real-estate-components/maps/LandsMapSidebar';
import LandsMap from '../components/real-estate-components/maps/LandsMap';
import HousesMapSidebar from '../components/real-estate-components/maps/HousesMapSidebar';
import HousesMap from '../components/real-estate-components/maps/HousesMap';
import ApartmentsMapSidebar from '../components/real-estate-components/maps/ApartmentsMapSidebar';
import ApartmentsMap from '../components/real-estate-components/maps/ApartmentsMap';
// import TestAzureMap from '../components/real-estate-components/maps/TestAzureMap';

const RealEstatesLocations = () => {

    return (
        <div>
            <Navbar2 />
            <PageHeader headertitle="Localisation des biens immobiliers" customclass="mb-0" />
            {/* <Location  /> */}
            <div className="ltn__real-estates-maps-tab container-fluid container-lg mt-120 mb-150">
                <div className="row">
                    <div className="ltn__tab-menu text-uppercase mb-1 text-center">
                        <div className="nav">
                            <a
                                className="active show"
                                data-bs-toggle="tab"
                                href="#ltn__form_tab_1_1"
                            >
                                <i className="fas fa-sign-hanging" />
                                Terrain
                            </a>
                            <a
                                data-bs-toggle="tab"
                                href="#ltn__form_tab_1_2"
                            >
                                <i className="fas fa-home" />
                                Maison
                            </a>
                            <a
                                data-bs-toggle="tab"
                                href="#ltn__form_tab_1_3"
                            >
                                <i className="fas fa-building" />
                                Appartement
                            </a>
                        </div>
                    </div>
                    <div className="tab-content">
                        <div
                            className="tab-pane fade active show"
                            id="ltn__form_tab_1_1"
                        >
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-xl-3 px-0">
                                    <LandsMapSidebar />
                                </div>
                                <div className="col-md-8 col-lg-8 col-xl-9 px-0">
                                    <div className="map-wrapper real-estates-map-wrapper">
                                        <LandsMap />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="ltn__form_tab_1_2"
                        >
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-xl-3 px-0">
                                    <HousesMapSidebar />
                                </div>
                                <div className="col-md-8 col-lg-8 col-xl-9 px-0">
                                    <div className="map-wrapper real-estates-map-wrapper">
                                        <HousesMap />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="tab-pane fade"
                            id="ltn__form_tab_1_3"
                        >
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-xl-3 px-0">
                                    <ApartmentsMapSidebar />
                                </div>
                                <div className="col-md-8 col-lg-8 col-xl-9 px-0">
                                    <div className="map-wrapper real-estates-map-wrapper">
                                        <ApartmentsMap />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Newsletter />
            <Footer />
        </div>
    );
};

export default RealEstatesLocations;
