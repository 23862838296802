import { useState, useEffect } from "react";

/**
 * 
 * @param data 
 * @param rowsPerPage 
 * @returns 
 */
const calculatePageCount = (data, rowsPerPage) => {
    return Math.ceil(data.length / rowsPerPage);
};

/**
 * 
 * @param data 
 * @param pageNumber 
 * @param rowsPerPage 
 * @returns 
 */
const sliceData = (data, pageNumber, rowsPerPage) => {
    return data.slice((pageNumber - 1) * rowsPerPage, pageNumber * rowsPerPage);
};

/**
 * 
 * @param data 
 * @param pageNumber 
 * @param rowsPerPage 
 * @returns 
 */
const usePagination = (data, pageNumber, rowsPerPage) => {
    /**
     * 
     */
    const [pageCount, setPageCount] = useState(1);

    /**
     * 
     */
    const [pageData, setPageData] = useState([]);

    /**
     * 
     */
    useEffect(() => {
        const page_count = calculatePageCount(data, rowsPerPage);
        setPageCount((page_count) ? page_count : 1);

        const slice = sliceData(data, pageNumber, rowsPerPage);
        setPageData([...slice]);
    }, [data, pageNumber, rowsPerPage]);

    return { pageData, pageCount };
};

export default usePagination;
