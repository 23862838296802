import React from 'react';
import Navbar from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import AboutV4 from '../components/section-components/about-v4';
import Features from '../components/section-components/features-v1';
import Team from '../components/section-components/team-v1';
import Testimonial from '../components/section-components/testimonial-v1';
import BlogSlider from '../components/blog-components/blog-slider-v1';
import Footer from '../components/global-components/Footer';
import Newsletter from '../components/global-components/Newsletter';


const AlfaImmo = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="ALFAIMMO" />
        <AboutV4 />
        <Features  customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---"/>
        <Team />
        <Testimonial />
        <BlogSlider />
        <Newsletter />
        <Footer />
    </div>
};

export default AlfaImmo;

