import React, { useCallback, useContext, useEffect, useState } from "react";

import DataContext from "../contexts/DataContext";
import api from "../../services/api";
import { useLoaderSpinnerContext } from "../../components/global-components/providers/LoaderSpinnerProvider";

/**
 * 
 * @returns 
 */
export const useDataContext = () => useContext(DataContext);

/**
 * Create the provider component
 *
 * @param param0 
 * @returns 
 */
const DataProvider = ({ children }) => {
    /**
     * 
     */
    const [lands, setLands] = useState([]);
    const [houses, setHouses] = useState([]);
    const [apartments, setApartments] = useState([]);
    const [realEstates, setRealEstates] = useState([]);

    const [regions, setRegions] = useState([]);
    // const [departments, setDepartments] = useState<Department[]>([]);
    const [municipalities, setMunicipalities] = useState([]);

    /**
     * 
     */
    const { setShow: setShowSpinnerLoader } = useLoaderSpinnerContext();

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchRealEstates = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-real-estates', null).then((res) => {
            setRealEstates(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    }, [setShowSpinnerLoader]);

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchLands = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-lands', null).then((res) => {
            setLands(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            console.log(error);
        });
    }, [setShowSpinnerLoader]);

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchHouses = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-houses', null).then((res) => {
            setHouses(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    }, [setShowSpinnerLoader]);

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchApartments = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-apartments', null).then((res) => {
            setApartments(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    }, [setShowSpinnerLoader]);

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchRegions = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-regions', null).then((res) => {
            setRegions(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    }, [setShowSpinnerLoader]);

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    // const fetchDepartments = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

    //     if(showSpinnerLoader){
    //         setShowSpinnerLoader(true);
    //     }
    //     api.get('/get-departments', null).then((res) => {
    //         setRegions(res.data);
    //         setTimeout(() => {
    //             if(showSpinnerLoader){
    //                 setShowSpinnerLoader(false);
    //             }
    //         }, spinnerTime);
    //     }).catch((error) => {
    //         setShowSpinnerLoader(false);
    //         // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
    //         console.log(error);
    //     });
    // }, [setShowSpinnerLoader]);

    /**
     * 
     * @param showSpinnerLoader 
     * @param spinnerTime 
     */
    const fetchMunicipalities = useCallback((showSpinnerLoader = true, spinnerTime = 2000) => {

        if(showSpinnerLoader){
            setShowSpinnerLoader(true);
        }
        api.get('/get-municipalities', null).then((res) => {
            setMunicipalities(res.data);
            setTimeout(() => {
                if(showSpinnerLoader){
                    setShowSpinnerLoader(false);
                }
            }, spinnerTime);
        }).catch((error) => {
            setShowSpinnerLoader(false);
            // showToast(`${t("Une erreur est survenue ! Veuillez réessayer ou contacter l'administrateur")}.`, 'error');
            console.log(error);
        });
    }, [setShowSpinnerLoader]);

    /**
     * 
     */
    const fetchData = useCallback(() => {

        setShowSpinnerLoader(true);
        fetchApartments(false);
        fetchLands(false);
        fetchHouses(false);
        // fetchRealEstates(false);

        fetchRegions(false);
        // fetchDepartments(false);
        fetchMunicipalities(true, 2000);
        setTimeout(() => {
            setShowSpinnerLoader(false);
        }, 2000);

    }, [
        fetchApartments,
        fetchHouses,
        fetchLands,
        fetchMunicipalities,
        // fetchRealEstates,
        fetchRegions,
        setShowSpinnerLoader
    ]);

    /**
     * 
     */
    useEffect(() => {
        fetchData();
    }, [fetchData]);

    /**
     * Define the context value
     */
    const contextValue = {
        apartments,
        // departments,
        houses,
        lands,
        municipalities,
        realEstates,
        regions,
        fetchApartments,
        // fetchDepartments,
        fetchData,
        fetchHouses,
        fetchLands,
        fetchMunicipalities,
        fetchRealEstates,
        fetchRegions,
        setApartments,
        // setDepartments,
        setHouses,
        setLands,
        setMunicipalities,
        setRealEstates,
        setRegions
    };

    /**
     * 
     */
    return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

export default DataProvider;
