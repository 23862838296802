import React from 'react';
import Navbar from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import ContactInfo from '../components/section-components/ContactInfo';
import ContactForm from '../components/section-components/ContactForm';
import Map from '../components/section-components/Map';
import Footer from '../components/global-components/Footer';
import Newsletter from '../components/global-components/Newsletter';

const Contact = () => {
    return <div>
        <Navbar />
        <PageHeader headertitle="Contact" subheader="Contact" />
        <ContactInfo />
        <ContactForm />
        <Map />
        <Newsletter />
        <Footer />
    </div>
};

export default Contact;

