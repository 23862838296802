import {
    components
} from 'react-select';

// import { Label } from 'reactstrap';

export const customSelectStyles = {
    control: (defaultStyles) => ({
        ...defaultStyles,
        // height: 'calc(3.5rem + calc(var(--bs-border-width) * 1))',
        // minHeight: 'calc(3.5rem + calc(var(--bs-border-width) * 1))',
        // height: '60px',
        // minHeight: 'calc(3.5rem + calc(var(--bs-border-width) * 2))',
        // lineHeight: '58px',
        fontSize: '16px',
        fontWeight: '500',
        margin: 0,
        fontFamily: 'var(--lt__heading-font)',
        backgroundColor: '#fff',
        // color: 'var(--body-color)',
        backgroundClip: 'padding-box',
        border: '1px solid #e8e8e8',
        borderRadius: 0,
        boxShadow: 'none !important',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        boxSizing: 'border-box',
        webkitUserSelect: 'none',
        mozUserSelect: 'none',
        msUserSelect: 'none',
        UserSelect: 'none',
        whiteSpace: 'nowrap',

        '&[aria-disabled="true"]': {
            color: 'hsl(0, 0%, 45%) !important',
            cursor: 'not-allowed !important',
            // pointerEvent: 'unset', 
            // backgroundColor: '#f6f6f7',
            // backgroundColor: 'transparent',
            opacity: 0.7
        },
        '&:focus': {
            borderColor: 'var(--ltn__secondary-color)',
        },
        '&:active': {
            borderColor: 'var(--ltn__secondary-color);',
        },
        '&:hover': {
            borderColor: 'var(--ltn__secondary-color);'
        }
    }),
    clearIndicator: (defaultStyles) => ({
        ...defaultStyles,
        color: 'inherit',
        opacity: 0.6,
        '&:hover': {
            color: 'inherit',
            opacity: 0.8,
            cursor: 'pointer'
        }
    }),
    dropdownIndicator: (defaultStyles) => ({
        ...defaultStyles,
        color: 'inherit',
        opacity: 0.6,
        '&:hover': {
            color: 'inherit',
            opacity: 0.8,
            cursor: 'pointer'
        }
    }),
    indicatorSeparator: (defaultStyles) => ({
        ...defaultStyles,
        // backgroundColor: 'rgba(246, 246, 246, 1)'
        opacity: '0.7'
    }),
    menuPortal: (defaultStyles) => ({
        ...defaultStyles,
        boxShadow: '0px 0px 50px 0px rgba(82, 63, 105, 0.15)',
        zIndex: 9999
    }),
    option: (defaultStyles, state) => ({
        ...defaultStyles,
        color: state.isSelected ? '#fff' : defaultStyles.color,
        backgroundColor: state.isSelected ? 'rgba(176, 33, 39, 0.88)' : (state.isFocused ? '#f6f6f6' : defaultStyles.backgroundColor),
        cursor: "pointer",
        '&:active': {
            color: "#fff",
            backgroundColor: 'var(--ltn__secondary-color)'
        }
    }),
    placeholder: (defaultStyles) => ({
        ...defaultStyles,
        color: 'inherit'
    }),
    singleValue: (defaultStyles) => ({ ...defaultStyles, color: "inherit" }),
};

/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectClearIndicator = ({ children , ...props }) => (
    <components.ClearIndicator
        {...props}
        className='select-clear-indicator'
    >
        {children}
    </components.ClearIndicator>
);

/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectDropdownIndicator = ({ children , ...props }) => (
    <components.DropdownIndicator
        {...props}
        className='select-dropdown-indicator'
    >
        {children}
    </components.DropdownIndicator>
);

/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectPlaceholder = ({ children , ...props }) => (
    <components.Placeholder
        {...props}
        className='select-placeholder'
    >
        {children}
    </components.Placeholder>
);

/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectValueContainer = ({ children , ...props }) => (
    <components.ValueContainer
        {...props}
        className={`select-value-container`}
    >
        {children}
        {/* <label className={`select-label`}>{props.selectProps.placeholder?.toString()}</label> */}
    </components.ValueContainer>
);

/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectControl = ({ children , ...props }) => (
    <components.Control
        {...props}
        className={`select-input ${props.isFocused || props.hasValue ? 'floating':''}`}
    >
        {children}
    </components.Control>
);

/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectOption = ({ children , ...props }) => (
    <components.Option
        {...props}
        className='select-option'
    >
        {children}
    </components.Option>
);

/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectMenu = ({ ...props }) => (
    <components.Menu
        {...props}
        className='select-menu'
    >
        {props.children}
    </components.Menu>
)
;
/**
 * 
 * @param param0 
 * @returns 
 */
export const customSelectMenuList = ({ ...props }) => (
    <components.MenuList
        {...props}
        className='select-menu-list'
    >
        {props.children}
    </components.MenuList>
);
