import React from "react";
import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../services/AssetHelpers";
const { BaseLayer } = LayersControl;

const RealEstateMap = ({realEstate, zoom = 13}) => {
    return (
        <MapContainer
            className="real-estate-map-container"
            center={[realEstate.latitude, realEstate.longitude]}
            zoom={zoom}
            attributionControl={true}
            inertia={true}
            scrollWheelZoom={false}
        >
            <LayersControl>
                <BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                </BaseLayer>
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
            </LayersControl>
            <Marker position={[realEstate.latitude, realEstate.longitude]}>
                {/* <Pane name="purple-rectangle">
                    <Rectangle bounds={[[0.1, 0.2], [realEstate.latitude, realEstate.longitude]]} pathOptions={{ color: 'purple' }} />
                </Pane> */}
                <Popup>
                    <div>
                        <h6>{realEstate.wording}</h6>
                        <p>{realEstate.description}</p>
                        <div>
                            <label>Prix:</label>
                            <span>
                                {formatNumber(realEstate.price)}{" "}
                                <label>FCFA</label>
                                {realEstate.status === "RENT" && (
                                    <label>/Month</label>
                                )}
                            </span>
                        </div>
                    </div>
                </Popup>
            </Marker>
        </MapContainer>
    );
};

export default RealEstateMap;
