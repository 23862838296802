import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import Navbar2 from '../components/global-components/Navbar2';
import PageHeader from '../components/global-components/PageHeader';
import LandSlider from '../components/real-estate-components/land-components/LandSlider';
import LandDetails from '../components/real-estate-components/land-components/LandDetails';
import Newsletter from '../components/global-components/Newsletter';
import Footer from '../components/global-components/Footer';
import { useDataContext } from '../data/providers/DataProvider';
import { useLoaderSpinnerContext } from '../components/global-components/providers/LoaderSpinnerProvider';

const Land = () => {

    const { setShow: setShowLoaderSpinner } = useLoaderSpinnerContext();
    const { lands } = useDataContext();
    const { id: landId } = useParams();
    const [land, setLand] = useState(undefined);

    useEffect(() => {

        if(land){
            setShowLoaderSpinner(false);
        }

    }, [land, setShowLoaderSpinner]);

    useEffect(() => {

        const _land = lands.find((l) => l.id === Number(landId));
        setLand(_land);

    }, [landId, lands]);

    const publicUrl = process.env.PUBLIC_URL + "/";

    return (
        <div>
            <Navbar2 />
            <PageHeader headertitle="Terrain" customclass="mb-0" />
            {/* <LandSlider land={land} /> */}
            {/* {land && <LandSlider land={land} />} */}
            <div
                className="bg-overlay-theme-black-10 position-relative bg-image w-100"
                data-bs-bg={`${publicUrl}assets/img/bg/img-${land && land.real_estate.municipality_id === 466 ? '3' : '2'}.jpg`}
                style={{zIndex: 0, minHeight: '350px'}}
            ></div>
            <div className='py-3'></div>
            {land && <LandDetails land={land} />}
            <Newsletter />
            <Footer />
        </div>
    );
};

export default Land;
