import React from "react";
import { Link } from "react-router-dom";
import Social from "../section-components/SocialsNetwork";

const Navbar2 = (props) => {

    let publicUrl = process.env.PUBLIC_URL + "/";
    let CustomClass = props.CustomClass ? props.CustomClass : "";

    return (
        <div>
            <header className={"ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- " + CustomClass}>
                {/* ltn__header-top-area start */}
                <div className="ltn__header-top-area ">
                    <div className="container-fluid container-lg">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="ltn__top-bar-menu text-start d-flex align-items-center justify-content-center justify-content-md-start">
                                    <ul className="d-flex flex-column flex-md-row align-items-start justify-content-center">
                                        <li>
                                            <a 
                                                href="mailto:commercial@alfaimmobilier.com"
                                                className="d-inline-flex align-items-center"
                                            >
                                                <i className="icon-mail" />{" "}
                                                <span>commercial@alfaimmobilier.com</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="tel:+221778076565"
                                                className="d-inline-flex align-items-center"
                                            >
                                                <i className="icon-phone-call" />{" "}
                                                <span>(+221) 77 807 65 65</span>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="locations.html" className="d-inline-flex">
                                                <i className="icon-placeholder pt-1" />{" "}
                                                <span>Grand Mbao Extension villa n°1329 en face stade de Mbao</span>
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="top-bar-right text-end">
                                    <div className="ltn__top-bar-menu">
                                        <ul>
                                            <li>
                                                {/* ltn__language-menu */}
                                                <div className="ltn__drop-menu ltn__currency-menu ltn__language-menu">
                                                    <ul>
                                                        <li>
                                                            <Link
                                                                to="#"
                                                                className="dropdown-toggle"
                                                            >
                                                                <span className="active-currency">Français</span>
                                                            </Link>
                                                            <ul>
                                                                <li>
                                                                    <Link to="#">Arabic</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#">Bengali</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#">Chinese</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#">English</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#">Français</Link>
                                                                </li>
                                                                <li>
                                                                    <Link to="#">Hindi</Link>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <Social />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__header-top-area end */}
                {/* ltn__header-middle-area start */}
                <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white">
                    <div className="container-fluid container-lg">
                        <div className="row">
                            <div className="col">
                                <div className="site-logo-wrap">
                                    <div className="site-logo go-top">
                                        <Link to="/">
                                            <img
                                                src={`${publicUrl}assets/img/logos/logo.jpg`}
                                                width="100"
                                                alt="Logo"
                                            />
                                        </Link>
                                    </div>
                                    <div className="get-support clearfix d-none">
                                        <div className="get-support-icon">
                                            <i className="icon-call" />
                                        </div>
                                        <div className="get-support-info">
                                            <h6>Obtenir de l'aide</h6>
                                            <h4>
                                                <a href="tel:+221786586868">(+221) 78 658 68 68</a>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col header-menu-column">
                                <div className="header-menu d-none d-xl-block go-top">
                                    <nav>
                                        <div className="ltn__main-menu">
                                            <ul>
                                                <li>
                                                    <Link to="/accueil">Accueil</Link>
                                                </li>
                                                <li className="menu-icon">
                                                    <button type="button">Biens immobiliers</button>
                                                    <ul className="sub-menu menu-pages-img-show">
                                                        <li>
                                                            <Link to="/terrains">Terrains</Link>
                                                            {/* <img
                                                                src={publicUrl + "assets/img/home-demos/home-1.jpg"}
                                                                alt="Illustration"
                                                            /> */}
                                                        </li>
                                                        <li>
                                                            <Link to="/maisons">Maisons</Link>
                                                            {/* <img
                                                                src={publicUrl + "assets/img/home-demos/home-1.jpg"}
                                                                alt="Illustration"
                                                            /> */}
                                                        </li>
                                                        <li>
                                                            <Link to="/appartements">Appartements</Link>
                                                            {/* <img 
                                                                src={publicUrl + "assets/img/home-demos/home-2.jpg"}
                                                                alt="Illustration"
                                                            /> */}
                                                        </li>
                                                    </ul>
                                                </li>
                                                {/* <li>
                                                    <Link to="/services">Services</Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/contact">Contact</Link>
                                                </li>
                                                <li>
                                                    <Link to="/alfaimmo">Alfaimmo</Link>
                                                </li>
                                                <li className="special-link">
                                                    <Link to="/localisation-des-biens-immobiliers">Localiser nos biens</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </div>
                            </div>
                            <div className="col--- ltn__header-options ltn__header-options-2 ">
                                {/* Mobile Menu Button */}
                                <div className="mobile-menu-toggle d-xl-none">
                                    <a
                                        href="#ltn__utilize-mobile-menu"
                                        className="ltn__utilize-toggle"
                                    >
                                        <svg viewBox="0 0 800 600">
                                            <path
                                                d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                                                id="top"
                                            />
                                            <path
                                                d="M300,320 L540,320"
                                                id="middle"
                                            />
                                            <path
                                                d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                                                id="bottom"
                                                transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ltn__header-middle-area end */}
            </header>
            <div
                id="ltn__utilize-mobile-menu"
                className="ltn__utilize ltn__utilize-mobile-menu"
            >
                <div className="ltn__utilize-menu-inner ltn__scrollbar">
                    <div className="ltn__utilize-menu-head">
                        <div className="site-logo">
                            <Link to="/">
                                <img
                                    src={publicUrl + "assets/img/logos/logo.jpg"}
                                    width="100"
                                    alt="Logo"
                                />
                            </Link>
                        </div>
                        <button className="ltn__utilize-close">×</button>
                    </div>
                    {/* <div className="ltn__utilize-menu-search-form">
                        <form action={"#"}>
                            <input
                                type="text"
                                placeholder="Rechercher..."
                            />
                            <button>
                                <i className="fas fa-search" />
                            </button>
                        </form>
                    </div> */}
                    <div className="ltn__utilize-menu">
                        <ul>
                            <li>
                                <Link to="/accueil">Accueil</Link>
                            </li>
                            <li>
                                <button type="button">Biens immobiliers</button>
                                <ul className="sub-menu menu-pages-img-show">
                                    <li>
                                        <Link to="/terrains">Terrains</Link>
                                        {/* <img
                                            src={publicUrl + "assets/img/home-demos/home-1.jpg"}
                                            alt="Illustration"
                                        /> */}
                                    </li>
                                    <li>
                                        <Link to="/maisons">Maisons</Link>
                                        {/* <img
                                            src={publicUrl + "assets/img/home-demos/home-1.jpg"}
                                            alt="Illustration"
                                        /> */}
                                    </li>
                                    <li>
                                        <Link to="/appartements">Appartements</Link>
                                        {/* <img
                                            src={publicUrl + "assets/img/home-demos/home-2.jpg"}
                                            alt="Illustration"
                                        /> */}
                                    </li>
                                </ul>
                            </li>
                            {/* <li>
                                <Link to="/services">Services</Link>
                            </li> */}
                            <li>
                                <Link to="/alfaimmo">Alfaimmo</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact</Link>
                            </li>
                            <li className="special-link ms-0">
                                <Link to="/localisation-des-biens-immobiliers">Localiser nos biens</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar2;
