import React from "react";

import { LayersControl, MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { formatNumber } from "../../../services/AssetHelpers";
import { useDataContext } from "../../../data/providers/DataProvider";
const { BaseLayer } = LayersControl;

const RealEstatesMap = () => {

    const { lands, houses, apartments } = useDataContext();

    // const customIcon = new L.Icon({
    //     iconUrl: require("./location.svg").default,
    //     // iconUrl: "./location.svg",
    //     iconSize: new L.Point(40, 47)
    // });
    return (
        <MapContainer
            className=""
            // center={[14.4994539, -14.445561388888889]}
            center={[14.692778, -17.446667]}
            zoom={10}
            scrollWheelZoom={false}
        >
            <LayersControl>
                <BaseLayer checked name="OpenStreetMap.Mapnik">
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution="&copy; <a href=&quot;https://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                </BaseLayer>
                <BaseLayer name="Goole streets">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Hybrid">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Satellite">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
                <BaseLayer name="Goole Terrain">
                    <TileLayer
                        url='https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'
                        maxZoom= {20}
                        subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                    />
                </BaseLayer>
            </LayersControl>
            {
                lands.map((land, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[land.real_estate.latitude, land.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{land.real_estate.wording}</h6>
                                    <p>{land.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(land.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {land.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
            {
                houses.map((house, index) => {
                    return (
                        <Marker
                            key={index}
                            // icon={customIcon}
                            position={[house.real_estate.latitude, house.real_estate.longitude]}
                        >
                            <Popup>
                                <div>
                                    <h6>{house.real_estate.wording}</h6>
                                    <p>{house.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(house.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {house.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
            {
                apartments.map((apartment, index) => {
                    return (
                        <Marker
                            key={index}
                            position={[apartment.real_estate.latitude, apartment.real_estate.longitude]}
                            // icon={customIcon}
                        >
                            <Popup>
                                <div>
                                    <h6>{apartment.real_estate.wording}</h6>
                                    <p>{apartment.real_estate.description}</p>
                                    <div>
                                        <label>Prix:</label>
                                        <span>
                                            {formatNumber(apartment.real_estate.price)}{" "}
                                            <label>FCFA</label>
                                            {apartment.real_estate.status === "RENT" && (
                                                <label>/Month</label>
                                            )}
                                        </span>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    );
                })
            }
            {/* <Marker position={[14.75, -17.43]}></Marker>
            <Marker position={[14.75, -17.46]}></Marker>
            <Marker position={[14.77, -17.37]}></Marker>
            <Marker position={[14.76, -17.35]}></Marker>
            <Marker position={[14.79, -16.93]}></Marker>
            <Marker position={[14.79, -16.94]}></Marker>
            <Marker position={[14.73, -17.08]}></Marker>
            <Marker position={[14.73, -17.07]}></Marker>
            <Marker position={[14.81, -17.02]}></Marker>
            <Marker position={[14.81, -17.19]}></Marker>
            <Marker position={[14.82, -17.26]}></Marker>
            <Marker position={[14.75, -17.21]}></Marker> */}
        </MapContainer>
    );
};

export default RealEstatesMap;
