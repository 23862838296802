import React, { useState } from 'react'
import {AzureMap, AzureMapDataSourceProvider, AzureMapFeature, AzureMapLayerProvider, AzureMapPopup, AzureMapsProvider, IAzureMapOptions} from 'react-azure-maps'
import {AuthenticationType, layer} from 'azure-maps-control'

import 'azure-maps-control/dist/atlas.min.css'
import { useDataContext } from '../../../data/providers/DataProvider';

const option = {
    authOptions: {
        authType: "subscriptionKey",
        subscriptionKey: "GGf71N1HDTvhaubOANAqgjHFIDME5qKb5JBmsBTgVlGbS9p2FHBqJQQJ99AKACYeBjFxxztfAAAgAZMP1RpY",
    },
    center: [-14.545898, 14.232438],
    zoom: 7,
    view: 'Auto',
    style: 'Hybrid.road',
    // layer: 'hybrid'
    
    // mapTypeId: "grayscale"

    // authOptions: {
    //     authType: "anonymous",
    //     clientId: 'e6b6ab59-eb5d-4d25-aa57-581135b927f0',
    //     getToken: (resolve, reject) => {
    //         // URL to your authentication service that retrieves an Azure Active Directory Token.
    //         var tokenServiceUrl = "https://samples.azuremaps.com/api/GetAzureMapsToken";
    //         fetch(tokenServiceUrl).then(r => r.text()).then(token => resolve(token));
    //     }
    // }
};

const controls = [
    {
        controlName: 'StyleControl',
        controlOptions: { mapStyles: 'all' },
        options: { position: 'top-left' },
    },
    {
        controlName: 'ZoomControl',
        options: { position: 'top-left' },
    },
    {
        controlName: 'CompassControl',
        controlOptions: { rotationDegreesDelta: 10, style: 'dark' },
        options: { position: 'bottom-right' },
    },
    {
        controlName: 'PitchControl',
        controlOptions: { pitchDegreesDelta: 5, style: 'dark' },
        options: { position: 'bottom-right' },
    },
    // {
    //     controlName: 'TrafficControl',
    //     controlOptions: { incidents: true },
    //     options: { position: 'top-left' },
    // },
    // {
    //     controlName: 'TrafficLegendControl',
    //     controlOptions: {},
    //     options: { position: 'bottom-left' },
    // },
];

const TestAzureMap = () => {

    const { lands } = useDataContext();
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div style={{height: 'calc(100vh - 150px)'}}>
            <AzureMapsProvider>
                <AzureMap
                    options={option}
                    controls={controls}
                    styleOptions={{
                        showLabels: true,
                        showLogo: false,
                        renderWorldCopies: false,
                        showFeedbackLink: false,
                    }}
                    styles={{width:'100%'}}
                >
                    <AzureMapDataSourceProvider id={'DataSource Provider'}>
                        <AzureMapLayerProvider
                            id={"Layer1"}
                            options={{
                                iconOptions: {
                                    image: 'marker-blue',
                                }
                            }}
                            type={"SymbolLayer"}
                            events={{
                                click: (e) => {
                                    // console.log('Test')
                                    if (e.shapes && e.shapes.length > 0) {
                                        setIsVisible(true);
                                        console.log('Test')
                                    }
                                }
                            }}
                        />
                        {
                            lands.map((land) => {
                                return (
                                    <AzureMapFeature
                                        key={land.id}
                                        id={'Pin1'}
                                        type="Point"
                                        coordinate={[land.real_estate.longitude, land.real_estate.latitude]}
                                        properties={{
                                            title: 'Pin',
                                            icon: 'marker-blue',
                                        }}
                                    />
                                )
                            })
                        }
                    </AzureMapDataSourceProvider>
                    {/* <AzureMapPopup
                        isVisible={isVisible}
                        options={{ closeButton: true,
                            position: [0, 0],
                            pixelOffset: [0, -5],           
                            showPointer: true}}
                            popupContent={<div>Hello World</div>}
                    /> */}
                    {/* <AzureMapDataSourceProvider id={'LayerExample2 DataSource'}>
                        <AzureMapLayerProvider id={'LayerExample2 HeatMap'} options={{}} type='TileLayer' />
                        <AzureMapFeature id={'LayerExample2 MapFeature2'} key={'dddd'} type="Point" coordinate={point1} />
                    </AzureMapDataSourceProvider> */}
                </AzureMap>
            </AzureMapsProvider>
        </div>
    );
};

export default TestAzureMap;
